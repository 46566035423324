.chatBorder{
    height: 90vh;
    width: 90vw;
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.15)!important;
    margin-bottom: 5em;
}
.singleChat{
    padding: 1em 0;
}
.singleChat .chat{
    width: 90vw;
    background-color: white;
}
#scrollableDiv{
    padding: 20px;
}
.singleChat .chatInput{
    background-color: white;
box-shadow: 0 1rem 3rem rgba(0,0,0,.1);
}
.singleChat .chatDiv{
    height: 55vh;
}
.alignBottom{
    display: flex;
    align-items:flex-end;
}
.chatContainer{
background-color:#f7f7f7;
min-height:87vh;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;

}
.contactList{
    flex: 1;
    border-radius: 10px;
padding: 20px 5px ;
text-align: start;
min-width: 240px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.15)!important;
} 
.chat{
    flex: 3;
    display: flex;
    flex-direction: column;
justify-content: space-between;
}
.contactContainer{
    overflow-y: auto;
   height: 66vh;
   padding: 10px 20px;
padding-bottom: 0;
}

.contactList .css-nen11g-MuiStack-root{
margin-top: 1em !important;
align-items: center !important;

}


.contactTitle{
    font-family: 'cairo';
    font-size: 18px;
    padding: 0 20px;

    display: flex;
    justify-content: space-between;
}
.contactSrch{
    position: relative;
    padding: 0 20px;

}
.contactSrch input{
border: none;
border-radius: 10px;
padding: 7px 1em;
padding-left: 2.1em;
width: 100%;
font-family: 'cairo';
background-color: #f7f7f7bd;
}
.contactSrch svg{
    position: absolute;
    left: 25px;
    top: 7px;
    color: #dddadabd;
}
.contactContainer{
    margin-top: 0.7em;
}
.contactImg div{
    width: 67px;
    height: 67px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
}

.contact{
display: flex;
justify-content: space-between;
align-items: center;
background-color: #f7f7f7bd;
padding: 10px;
border-radius: 5px;
margin-bottom: 1em;
cursor: pointer;
}

.contact:hover{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.05)!important;
 
}
.lastMsg{
text-overflow: ellipsis;
overflow: hidden;
    white-space: nowrap;
    max-width: 9vw;
}
.contactData p{
    font-family: 'Cairo';
    margin-bottom: 0;
    color: #7d7979bd;
    margin:0 0.5em;
    font-size: 16px;
}

.contactName{
    font-weight: 600;

    color: black !important;
    font-size: 17px !important;

}

.chatTitle{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.1);
text-align: start;
padding: 10px 15px;
align-items: flex-start;
display: flex;
flex-direction: column;
}
.chatTitle ul{
    margin-bottom: 0;
list-style: none;}
.chatTitle button{
    border: none;
    background-color: brown;
    width: 38px;
    height: 38px;
border-radius: 100%;
}
.chatTitle button:hover{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.1);

}
.chatTitle svg{
    color: white;
    width: 25px;
    height: 25px;
}
.chatTitle p{font-family: 'cairo';
font-size: 16px;
color: #7d7979bd;
    margin-bottom: 0;
}
.cntctName{
    color: black !important;
    font-size: 20px !important;
    font-weight: 600;
}
.chatInput input{
    width: 100% ;
    border: none;
    border-radius: 8px;
font-family: 'Cairo';
    background-color: #f7f7f7bd;

}
.chatInput input:focus{
    border: none !important;  
}
.chatInputDiv{
    width: 100% ;
    padding:  10px;
    background-color: #f7f7f7bd;

border-radius: 8px;
    margin: 0 1em;
    border: 2px solid #ccc8c838 !important;
}
 .chatInputDiv:focus-within {

    border: 2px solid #E47100 !important;

}
.attachedImg img{
    max-height: 90px;

}
.attachedImg{
    max-height: 90px;
  width: fit-content;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 1em;
    position: relative;
}
.attachedImg button{
position: absolute;
left: -12px;
top: -12px;
width: 22px;
height: 22px;
display: flex;
align-items: center;
justify-content: center;
border: none;
border-radius: 100%;
font-family: 'Cairo';
font-weight: 700;
color: #E47100;
box-shadow: 0 1rem 8rem rgba(0,0,0,.08)!important;
}
.csLogo div{
    background-size: contain;
    background-position:  center ;
   border: 2px solid #E47100;

}
.attachedImgEn button{
    right: -12px;
    left: auto !important;  
}
.attachedImg button:hover{
    color: #e9821c;
  
}
.txtTop{
    display: flex;
    justify-content: space-between;
}
.Text p{
    overflow-wrap: break-word;
}
.chooseReply{
    background-color: #E47100;
    color: white;
    font-family: 'cairo';
    white-space: nowrap;
    padding: 5px 1em;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: none;
}
.chooseReply:hover{
    background-color: #e9811a;

}
.reply{
    position: absolute;
    background-color: white;
    bottom: 80px;
    padding: 15px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.08)!important;
border-radius: 8px 8px 8px 0;
left: 90px;
overflow-y: auto;
height: 260px;
}
.closeReply{
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 11;
    background: rgba(49,49,49,0.8);
}
.replyAr{
    left: auto !important;
    right: 90px;   
}
.reply p{
    max-width: 260px;
    text-align: start;
    font-family: 'Cairo';
    padding: 10px 5px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 0;
    border-bottom: 1.5px solid rgba(128, 128, 128, 0.109);
}
.reply p:Hover{

   background-color: rgba(128, 128, 128, 0.109); 
}
.chatInput{
    position: relative;
 width: 100%;
border: none;
align-items: center;
background-color: #f7f7f7bd;
padding: 10px 20px;
border-radius: 8px;
display: flex;
justify-content: space-between;
}


.chatInput svg{
    color: #E47100;
   
    cursor: pointer;
    background-color: white;
    width: 35px;
    height: 35px;
    padding: 8px;
    border-radius: 100%;
  margin-left: auto;
}
.infinite-scroll-component{
    display: flex;
    flex-direction: column-reverse;
}
.chatDiv{
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    
}
.contactImg p{
    display: block;
    color: #7d7979bd;
    font-size: 14px;
    font-family:'Cairo';
    margin-top: 0.5em;
   white-space: nowrap;
text-align: start;}
.Text{
    text-align: start;
    font-family:'Cairo';
border-radius: 8px 8px 8px 0;
    background-color: #eae8e882;
    padding: 10px;
margin: 1em;
margin-bottom: 0.5em;
width: 45%;
max-width: 400px;
min-width: 400px;
}
.textDiv{
    display: flex;
    align-items: center;
}
.textDate{
    width: 45%;
    max-width: 415px;
    min-width: 415px;
    text-align: end;
    font-family:'Cairo';
    color: #7d7979bd;
    font-size: 14px;
    
}
.contactMobileBtn{
    display: none;
}
.textDiv2{
    display: flex;
    align-items: center;
    justify-content: end;
}
.textDiv2
.textDiv2 .contactData p, .textDiv .contactData p{
    margin: 0;
}
.textDateAr{
text-align: start;
}
.textDiv2 .Text{

border-radius: 8px 8px  0 8px;
background-color: #e472003b;


}
.textDiv .contactImg div{
    width: 60px;
    height: 60px;
}
.textDiv2 .contactImg div{
margin-left: auto;
}
.textDiv2 .contactImg p{
    text-align: end;
}
.chatContainerAr .Text{ 

    border-radius: 8px 8px  0 8px !important;
}
.sendBtn{
    border: none;
    background-color: transparent;
    padding: 0;
}
.sendBtn:disabled{
    background-color: transparent !important;
    border: none;

}
.txtTop p{
    margin: 0 !important;
}
.sendBtn:disabled svg{
color: #b3712f;
}
.chatContainerAr .textDiv2 .contactImg div{ 
margin-right: auto !important;
margin-left: 0 !important;
}
.chatContainerAr .textDiv2  .Text{ 
    border-radius: 8px 8px  8px 0  !important;
    margin-right: auto !important;
    }

.chatContainer .filtering{
    width: 90vw;
    margin-bottom: 2em;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.15)!important;
    border: none !important;
}



.mobileClose{
    display: none;
}
.showChat{
    position: relative;
}

.chatSvg:hover::before{
/*
   background-color: white;
   color: #e47100;
   box-shadow: 0 3px 6px rgb(0 0 0 / 16%);*/
}
.chatSvg::before{

content: attr(data-count);
position: absolute;
top:-1em;
right: -1em;
width:1.3em;
height: 1.3em;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
background-color: #e47100;
color: white;
}

@media screen and (max-width: 865px) {
    .Text{
        min-width: 330px;
        max-width:330px
    }
    .textDate{
        width: auto;
        min-width: unset;
        margin:0 1em;
      
        
    }

}
@media screen and (max-width: 771px) {
    .mobileHide{
        display: none !important;
    }
.contactList{
    position: absolute;
    background-color: white;
    z-index: 9;
    transition: 1s ease-in-out;
    left: -330px;
}
.open{
    left: 0 !important;  
 
}

.chatContainer{
    position: relative;
    overflow: hidden;  
}

.chatBorderAr .contactList{
    left:  auto !important;

    right: -400px;
  }
.chatBorderAr .open{
    left:  auto !important;
    right:0!important;
}
.chatBorderAr .contactMobileBtn{
    left:  auto !important;
}
.open .mobileClose svg{
    cursor: pointer;
    
}
.open .mobileClose{
display: block;
}
.contactMobileBtn{
    display: block;
    border: none;
    position: fixed;
    background-color: white;
    border-radius: 100%;
    top: 110px;
    left: 10px;
    box-shadow: 4px 4px 10px rgba(228, 113, 0, 0.1);

    padding: 0.5em;
}
}
@media screen and (max-width: 525px) {
    .Text{
        min-width: 64vw;
        max-width:64vw;
        margin: 0.5em;
    } 
    .chatTitle p{
        font-size: 14px;
    }  
    .cntctName{
        font-size: 16px !important;
    }
    .contactImg div{
        width: 55px;
    height: 55px;
    }
    #scrollableDiv{
        padding:  10px;
    }
    .textDate{
        margin: 0.5em;
    }
    .Text p,.contactData p{
        font-size: 14px !important;
        line-height: 16px;

    }
    .contactName{
        font-size: 16px !important;
        max-width: 104px;

    }
   
    .txtTop{
        margin-bottom: 0.5em;
    }
    .chatInputDiv{
        margin: 0 0.5em;
        padding: 5px;
    }
    .sendBtn{
        margin: 0 0.3em !important;

    }
    .sendBtn svg{
        width: 27px;
        height: 27px;
        padding: 6px;
    }
    .chatInput{
        padding: 10px;
    }
    .contactTitle{
        font-size: 16px;
    }
    .chooseReply{
        padding:5px 10px;
        font-size: 12 !important;
    }
}

.css-2gftvx{
    margin: 0 !important;
    border-radius:0 !important;
}
.contactList .css-j7qwjs{
    align-items: center !important;
} 
.noWifi{
    width: 100%;
    height: 50vw;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.connectP{
    font-family: 'cairo';
    font-size: 26px;
    margin-bottom: 0;
    position: relative;
    bottom: 36px;

}
.connectP2{
    font-family: 'cairo';
    font-size: 18px;
    position: relative;
    bottom: 30px;

}