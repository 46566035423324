
.table th {
    border-top: none!important;
    padding: 1em!important;
    padding-top: 0 !important;
    margin-bottom: 1rem;
    color: #212529;
    font-family: 'Cairo';
    font-size: 1rem; 

   
   
}
.cardTop{
  display: flex;
  align-items: center;
}
.invoiceCard{
  background-color: #7073752f;
  border-radius: 6px;
  padding: 10px;
  display: block !important;
}
.invoiceData{
  margin: 1em 0;
  font-family: 'Cairo';
}
.invoiceData p{
  margin-top: 0 !important;
}
.invoiceData div{
  margin-top: 0.3em !important;
}
.pickup{
  font-weight: 500 !important;
  margin: 0 !important;
  margin-top: 8px !important;
}
.areaTable th{

  padding-top: 1em !important;
}

    .table td {
        border-top: none!important;
        padding: 1em!important;
   
        color: #212529;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5;
        font-family: 'Cairo';


    }
  th::after {

content: "" !important;
    }
th::before {

        content: "" !important;
            }
    
    .table-striped>tbody>tr:nth-of-type(odd)>* {
        --bs-table-accent-bg: none !important;
        color: var(--bs-table-striped-color);
      
    }
    tr{
        border-bottom: transparent;  
    }
.fixedHeader{
  text-align: center;
  margin: 1em 0;
  padding-bottom: 0.5em !important;
  padding: 2em 2.8em;
  position: fixed;
  width: 100%;
  top: -20px;
left: 0;

  background: white;
  z-index: 11;
}
.fixedHeader tr{
  display: inline-block 
}
.sorting::after{
    content:"" !important;
}
.sorting::before{
    content:"" !important;
}
.sorting_asc::after{
    content:"" !important;
}
.dataTables_length label{
    display: grid;
    font-family: 'Cairo';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;

}

.taxForm span{
  color: brown;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Cairo';
}
.taxTitle p{
  margin-bottom: 0;
  display: inline-block;
  color: #263238;
font-size: 18px;
font-weight: 700 !important;
line-height: normal;
}
.taxData  p span{
  color:  #98A2B3 !important;

font-size: 16.5px !important;

font-weight: 500 !important;
}
.taxCard{
  padding: 18px;
  border-radius: 8px;
border: 1.023px solid #BDBDBD;
}

.taxData  p{
  margin-bottom: 15px;
  height: auto !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  color:  #344054;
  font-family: Cairo;
  
  font-style: normal;
  font-weight: 600;
  
  }

.dataTables_length select{
    margin-left: 0 !important;
width: 180% !important;
margin-top: 0.5em;
cursor: pointer;
direction: ltr !important;
}
select:focus{

    border: 2px solid #E47100 !important;
    outline: none !important;
  
}
input:active,
input:focus,
input:focus:active {
  background-image: none !important;
  outline: 0;
  box-shadow: none !important;
}
select:active,
select:focus,
select:focus:active {
  background-image: none !important;
  outline: 0;
  box-shadow: none !important;
 
}

.pagination .page-item.active .page-link {
    color: #fff;
    background-color: #E47100;
  
}
.pagination .page-item.active .page-link:hover {
    color: #212529;
    background-color: #E47100;
  
}
.page-link {
    color: #e47100;
    cursor: pointer;
    border-color: #e47100;
}
.page-link:hover {
    color: #fff;
    border-color: #e47100;
    background-color: #E47100;}


    .dataTables_info{
font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    }

   .data-title{
    font-family: 'Cairo';
    font-weight: 600;
    font-size: 1.25rem;
    text-align: start;
   }
   .order-Data .p-3{
    border: 2px solid #ccccce !important;
    background-color: #fbfbfb !important;
    border-radius: 9px;
   
   }
   .order-Data .p-3 div{
display: flex;
justify-content: space-between;

margin-bottom: 1em;
   }
   .order-Data .orderHead{
    color: #939393;
    font-weight: 600;
    font-size: 1rem;
    font-family: 'Cairo';
       }
       .order-Data .headData{
        font-weight: 600;
        color: #331900;
        font-size: 1rem;
        font-family: 'Cairo';

       }
       .order-status{
           padding: 3em 0;
           margin-top: 3em;
        border-radius: 9px;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
        display: flex;
        align-items: center;
       }
       .order-status .col div{

        height: 70px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-bottom: 1.5em;
       }
       .order-status .col p{

        color: #c9c9c9;
        font-weight: 600;
        font-family: 'cairo';
        font-size: 1rem;
margin-bottom: 0;
       }
  .order-Data .orderHr{
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  .orderItems{
    border: 2px solid #ccccce !important;
padding: 2em 0;
    border-radius: 9px;
margin-top: 3em;
  }
  .itemImg{
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 25px;
  }
  .itemTitle{
    font-weight: 400;
    line-height: 1.2;
    font-size: 18px;
    font-family: 'cairo';
    text-align: start;
    margin-bottom: 0.2em;
  }
  .itemData{
    color: grey;
    font-weight: 600;
    line-height: 1.2;
    font-size: 16px;
    font-family: 'cairo';
    text-align: start;
  }

  .itemExtra{
    font-weight: 400;
    line-height: 1.2;
    font-size: 1rem;
    font-family: 'cairo';
    text-align: start;
    margin-bottom: 0;
    color: #212529;
  }
  .verticalAlign{
      position: relative;
      top: 50%;
  }
  .marginBtwn{
      margin: 4em 0;
  }
  .detailShow a{

    color: #E47100 !important;
    text-decoration: none ;
  }
  .pagination .page-item .page-link {
font-weight: 300;
box-shadow: none !important;
border: 1px solid #dee2e6;
color: #E47100;
  }
.spaceBTn{
    display: flex;
    justify-content: space-between;
 
}
.spaceBTn button{
    border: 1px solid #633235;
    text-align: center;
  color: white;
    padding: 5px 20px;
    border-radius: 10px;
position: relative;
bottom:15px;
    background-color: #633235;
    font-family: 'Cairo';
}
.spaceBTn button:hover{

    background-color: #783b3f;
}
a{
  text-decoration: none;
}


button:disabled,
button[disabled]{
 
  background-color: #dac3ac !important;
 
  cursor: default;
}
.col-sm-12{
  overflow-y: auto;
}
.driverPhone p{
  font-family: 'Cairo';
  margin-bottom: 0;
}
 
.pagAr .page-item:first-child .page-link{
  border-radius: 0 5px 5px 0 !important;
}
.pagAr .page-item:last-child .page-link{
  border-radius: 5px 0 0 5px !important;

}
.allOrders th{
line-height: 25px;
padding: 0.5em 1em;
font-size: 16px;
}
.allOrders td{
  font-size: 16px;
  line-height: 23px;
  padding: 0.5em 1em;
}
.allOrders p{
  margin-bottom: 0;
}
.allOrders button{
  color: #E47100;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.allOrders button:hover{

  color: #ed8925;
}
.allOrders table,  .allOrders th, .allOrders td {
  border: 1.5px solid rgba(157, 157, 157, 0.371);
  border-collapse: collapse;
}
.filtering input{
  font-family: 'Cairo';
  width: 100%;
  display: block !important;
  padding: 0.4em 0.5em;
  border-radius: 6px;
  border: 1px solid rgba(157, 157, 157, 0.371);
}
.filtering label{
  font-family: 'cairo';
  display: block !important;
  text-align: start;
}
.filtering{
  margin-top: 1em;

  border: 2px solid #ccccce!important;
  border-radius: 9px;  padding: 1em;

}
.filtering select{
  width: 100%;
  padding: 0.15em 0.5em;
  border-radius: 6px;
  border: 1px solid rgba(157, 157, 157, 0.371);
  color: black;
  font-family: 'Cairo';
  display: block !important;
}


.p-multiselect{
  width: 100%;
  border: 1px solid black;
}
.p-focus{
  border: 2px solid #E47100;
  border-color: #E47100 !important;
  box-shadow: none !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.175rem 0.75rem !important
}
.p-multiselect .p-multiselect-label {
  padding: 0.4rem 0.75rem !important;}
  .p-multiselect .p-multiselect-trigger{
    width: 2rem !important;
  }
  .filter{
    border: none;
    background-color: #E47100;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
    padding: 0.3em 1em;
    width: 100%;
    
    }
    .filter:hover{
    
    background: #f58617;
    }
    #invoice{
      height: unset !important;
      text-align: start;
      font-family: 'Cairo';
      position: fixed !important;
    }
    #invoice p{
font-size: 30px;
    }
    #invoice span{
      font-weight: 600;
    }
    #invoice ul{
    
    }
    .invoiceBtn{
      background-color: transparent !important;
    }
    .filterTitile{
  text-align: start;
    }
    .filterTitile p{
      font-family: 'Cairo';
      color: #331900;
      font-weight: 600;
      font-size: 23px;
      margin:0 ;
    }
    .filter:disabled{
      color: #eea65e;
    }
    .backBtn1{
      text-align: center;
      margin-top: 1em;
    }
    .backBtn1 button{
      background-color: #fff;
      border: 1px solid #e47100;
      border-radius: 8px;
      color: #e47100;
      font-family: Cairo;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -.3px;
   
      padding: 0.2em 1em;
      white-space: nowrap;
    }
    .backBtn{
      text-align: end;
      margin-top: 1em;
    }
    .backBtn button{
      background-color: #fff;
      border: 1px solid #e47100;
      border-radius: 8px;
      color: #e47100;
      font-family: Cairo;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -.3px;
   
      padding: 0.2em 1em;
      white-space: nowrap;
    }
    .backBtn svg {
      position: relative;
      transition: -webkit-transform .8s;
      transition: transform .8s;
      transition: transform .8s,-webkit-transform .8s;
  }
  
  .backBtn button:hover, backBtn1 button:hover{
    background-color: #e47100;
    color: white;
  }
  .backBtn button:hover svg{
    transform: translateX(8px);
  }
  .backBtnEn  button:hover svg{
    transform: translateX(-8px) !important;
  }
  .allOrders th{
    white-space: nowrap;
  }
  .prevAr a{
    border-radius: 0 .125rem  .125rem  0 !important;
  }
  .nextAr a{
    border-radius:.125rem 0 0 .125rem !important;

  }
  .pagination a{
    font-family: Cairo;
  }
  .pagination{
    margin-top: 1em;
  }
  .pagination{
   justify-content: end !important;
  }

  .paginate{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Cairo";
    gap: 5px;
  }
  .paginate .page-num{
   
    cursor: pointer;
    border-radius: 3px;
    font-family: "Cairo";

  }
  .paginate .page-num:hover{
    background-color: #E47100;
    color: white; 
  }

  .activePage{
    padding: 0px 8px;
    border-radius: 3px;

    background-color: #E47100;
    color: white !important;
  }
  .css-nen11g-MuiStack-root{
 align-items: flex-end;
 margin-top: 3em;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: #E47100 !important;
    color: white !important;
    border: 1px solid #e47100;


  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root{
    margin: 0 !important;
    border: 1px solid #dee2e6;

    border-radius: 0 !important;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover{
    background-color: #E47100 !important;
    color: white !important; 
    border: 1px solid #e47100;

  }
  .css-nen11g-MuiStack-root button{
    font-family: 'Cairo';
  }
  .arArrow .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon{
    transform: scaleX(-1);
  }
.emptyData{
  height: 95px;
}
.MuiPaginationItem-icon{
padding: 0 0.5em;}

prevAr.MuiPaginationItem-icon::before {
font-family: "cairo";
  content: "السابق ";
  font-size: 14px;

}
prevEn.MuiPaginationItem-icon::before {
  font-family: "cairo";
    content: "Prev";
    font-size: 14px;

  }
  nextAr.MuiPaginationItem-icon::before {
    font-family: "cairo";
      content: "اللاحق";
      font-size: 14px;

    }
    nextEn.MuiPaginationItem-icon::before {
      font-family: "cairo";
        content: "Next";
        font-size: 14px;

      }
      .css-1s2u09g-control{
        overflow: scroll;
        white-space: nowrap;
      }

      @media screen and (max-width: 550px) {
        .backBtn button{
          font-size: 14px;
        }
        .filtering label{
          font-size: 14px;

        }
        .filterTitile p{
          font-size: 20px !important;

        }
        .css-319lph-ValueContainer{
          font-size: 14px !important;

        }
        .orderDetails li{
          font-size: 14px !important;

        }
        .backBtn button{
          font-size: 14px !important;

        }
        .allOrders th{
          font-size: 14px !important;

        }
        .table td{
  font-size: 14px !important;

}
.invoiceTable{
  padding: 1em;
}
.table th{
  font-size: 14px !important;

}
.dataTables_info{
  font-size: 14px !important;

}
        .allOrders td{
          font-size: 14px !important;
          line-height: 16px !important;

        }
        .invoice{
          font-size: 18px;
          margin-top: 0 !important;

        }
        .toMenu{
          font-size: 14px ;

        }
        .navigateOrder{
          font-size: 14px ;
 
        }
        .orderTitle{
          font-size: 18px ;

        }
        .orderData p{
          font-size: 14px ;

        }
        .orderData label{
          font-size: 13px ;

        }
        .addAddress button{
          font-size: 14px ;

        }
        .processNav p{
          font-size: 14px ;

        }
        .addAddress label{
          font-size: 15px ;

      }
    .mrginAr{
      margin-left: 0.5em !important;
    }
    .mrginEn{
      margin-right: 0.5em !important;
    }
    .addAddress button{
      font-size: 14px !important ;
 
    }
    .branches .branch div{
      font-size: 14px !important ;
      padding:1em;
    }
    .processNav img{
      width: 45px;
    }
    .address-name{
      font-size: 15px  ;

    }
    .address-st{
      font-size: 14px;

    }
    .addressTop button{
      font-size: 14px;

    }
    .address button{
      font-size: 14px;
 
    }
    .datePicker input{
      font-size: 14px;
    }
    .customerBody p{
        font-size: 18px;
    }
    .addressAdd label{
      font-size: 14px;
    }
    .addressAdd select{
      font-size: 14px;

    }
    .addressAdd button{
      font-size: 14px;

    }
    .customerNav p{
      font-size: 14px;

    }
    .customerNav span{
      margin: 0 0.5em;
    }
    .addressAdd textarea{
      font-size: 14px;

    }
    .currentCredit p{
      font-size: 14px ;
  }
  .currentCredit{
    padding: 10px 0;
  }
  .credit{
      font-size: 26px !important;
    
  }
  .pointExchange-title{
    font-size: 16px !important;
  }
  .pointExchange img{
    margin: 20px;
    margin-top: 0;
    margin-bottom: 14px;
  }
  .pointExchange-exchange p{
    font-size: 14px ;

  }
    } 
      