.myNav{
    background: #633235;
box-shadow: 0px 5px 17px rgba(0, 0, 0, 0.15);
padding: 0.5em 1em;
position: sticky;
top: 0;

} 
  .imgAlign{
      display: flex;
      align-items: center;
  }

.navLogo{
    min-width: 160px;
 
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
position: relative;

cursor: pointer;
}
.navPartition{
    border-right: 1px solid #926f72;
    height: 35px;
    display: inline-block;
   
}
.myNav input{
    background: rgba(0, 0, 0, 0.2);
border-radius: 8px;
border: 2px solid transparent;
padding: 0.3em 1em;
color: white;
width: 100%;
}
.searchIcon{
    color: white;
    position: absolute;
    left: 18px;
}
.alignSearch{
    display: flex;
    align-items: center;
    position: relative;
}
.myNav input::placeholder{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 14px;

letter-spacing: -0.3px;

color: #BEBEBE;

}
.myNav select{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;

color: #FFFFFF;
background: transparent;
border: 2px solid transparent;;
cursor: pointer;
}
.alignLang{
    display: flex;
    align-items: center;
    justify-content: end;
}


.profileImage{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;

    border-radius: 100px;
    width: 35px;
    height: 35px;

  
}
.imgBorder{
    border: 2px solid #E47100;
    border-radius: 50%;
    padding: 0.3em;
    display: flex;
    align-items: center;
    width: fit-content;
    display: inline-block;
}
.usrName{
    display: inline-block;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
letter-spacing: -0.3px;
white-space: nowrap;
color: #FFFFFF;
margin-bottom: 0em;
margin-right: 0.5em;
}
.alignUser{
    display: flex;
    align-items: center;
    justify-content: end;
}
.stickyTop{
    position: sticky;
    top: 0;
    z-index: 10;
}

.myNav select option {

    background: #633235;

 
}

.toast{
    position: absolute;
    z-index: 10;
    right: 10px;
    
    }
    .toast-header{
    justify-content: center;
    position: relative;
    background-color: #E47100 !important;
    }
    .toast-header p{
    
        margin-bottom: 0;
        font-family: 'Cairo';
        font-size: 18px;
        color: white;
    
    }
    .toast-header .btn-close {
    
        position: absolute;
        left: 0;
    }
    .screens a{
        color: white;
        font-family: 'Cairo';  
        white-space: nowrap;
    }
    .screens a:hover{
        color: #E47100;
    }
    .active{
        color: #E47100 !important;

    }
    .active svg{
        color: #E47100 !important;
 
    }
    .ticker p{
font-size: 24px;
color: black;
font-family: 'Cairo';
display: flex;
    }
    .ticker span{
        padding: 0 1em;
     white-space: nowrap;

    }
    .ticker {
        height:36px !important;
    padding: 1em 0;
        background: #f3e2dd;
        box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
    }
  