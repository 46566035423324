
.carousel-item{
    height: 100%;
  }
  .crslBackground{
    height: 30vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    
    border-radius: 20px;
    min-height: 200px;
  }
  .saveBtn{
      text-align: end;
      margin-bottom: 2em;
  }
  .saveBtn button{
      width: 150px !important;
  }
  .orderTable td input{
      display: block !important;
      padding: 5px 10px;
      border: 1px solid rgb(199, 198, 198);
      border-radius: 6px;
      width: 100px;
      text-align: center;
  }
  .w-100{
    background-position: center bottom;
    background-size: cover;
    background-repeat: none;
  }
  
  .carousel-indicators [data-bs-target] {
  background-color: #E47100 !important;
  
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
  
    background-color: black !important;
    border-radius: 50px;
   
  }
  .w-100{
  height: 100% !important;
  }
   .crslAr .carousel-control-next {
  
  left: 0 !important;
  right: auto !important;
  transform: rotate(180deg) !important;
  }
  .crslAr .carousel-control-prev {
  right: 0 !important;
  transform: rotate(180deg) !important;
  }
  
  .carousel-control-next-icon {
  padding: 1.2em !important;
  }
  .carousel-control-prev-icon {
  
    background-size: 60% !important;
  }
  
  .carousel-control-prev-icon {
    padding: 1.2em !important;
    }
    .carousel-control-next-icon{
    
      background-size: 60% !important;
    }


.crslBackground2{
    height: 40vw;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center ;
  position: relative;
   max-height: 30em;
   
  }

  .crslBackground2Thumbs{
    height: 20vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
  
   max-height: 150px;

  }
  .swiper{
    height: auto !important;
    max-height: 30em;
 
}
.myCarousel .swiper{
    height: auto !important;

}

.swiper-thumbs{
    height: 15vw !important;
    margin-top: 2em;
  
}
.swiper-pagination-progressbar-fill{
border-radius: 20px !important;

}
.carousel .slide img {

    height: 40vw !important;
    min-height: 250px;
    display: none;
}
.carousel{
    text-align: center;
}
.carousel .control-dots .dot {

background: #e47100 !important;
box-shadow: none !important;
}
.carousel .thumb.selected{

    border: 3px solid #e47100 !important;  
}
.carousel .thumb:hover{
    border: 3px solid #e47100 !important; 
}
.dmfLfw{
    overflow: visible !important;
}
.eGeitL{
    overflow: visible !important;
}
.carousel-root {

    direction: ltr;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev{
    background-color: rgba(104, 100, 100, 0.287);
    border-radius: 50%;
  height: 40px;
  width: 40px;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
    font-size: 20px;
    position: relative;
  
    left: 1.5px;
    right: auto;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    font-size: 20px;
    right: 1.5px;
    left: auto;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
    background-color: rgba(104, 100, 100, 0.287);

    border-radius: 50%;
    height: 40px;
    width: 40px;

}
.crslBackground2 p{
    font-family: 'cairo';
    color: white;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.326);
    padding: 0.5em 1em;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
}

.customerSharesImg{
    height: 11em;
  
    width: 100% ;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;
    border-radius: 10px;
   
position: static !important;
opacity: 1 !important;
}
.offerImg{
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 12px rgb(0 0 0 / 12%);
    padding: 1em;
    cursor: pointer;
    height: 100%;
}
.offerImg p{
    font-family: 'Cairo';
    margin-top: 1em;
    font-size: 18px;
}

/* Base style for all category divs */
.cate-item {
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: 10px;
    cursor: pointer;
}

/* Style for text inside the category divs */
.cate-item p {
    color: white;
    margin: 5px auto;
}

/* Default (non-selected) state */
.cate-default {
    background-color: #E9E9E9;
    color: black;
}
.cate-default p {
    color: black;
    margin: 5px auto;
}

.cate-selected {
    background-color: #e47100;
    color: white;
}

.categories-offers {
    display: flex;
    flex-direction: row;
    border: none;
}

.custom-datatable  {
    width: 200vw !important; 
}