
.table-bordered {
  border-collapse: collapse;
  width: 100%;
}
* {
font-family: 'cairo';

}

.table-bordered thead th {
  border: 1px solid black;
}

.table-bordered tbody td {
  border: none !important;
}

.table-bordered tbody tr {
  border: none !important;
}

.printDiv{
  max-width: 440px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: -1px 1px 4px rgba(51, 51, 51, .2);
  background-color: #fff;
}

@media print {
  body {
    margin: 0;
    padding: 0;
    font-family: 'cairo';

  }

  .table-bordered {
    border-collapse: collapse;
    width: 100%;
  }
  
  .table-bordered thead  {
    border: 1px solid black;
  }
  
  .table-bordered tbody  {
    border: none !important;
  }
  
  .table-bordered tbody  {
    border: none !important;
  }

  .invoice-container {
    width: 100%;
    height: 50%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .printDiv{
    max-width: 440px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: -1px 1px 4px rgba(51, 51, 51, .2);
    background-color: #fff;
  }

  .invoiceDiv {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    background-color: white !important;
  }

  .invoiceTableDiv {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    border: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 !important;
    padding: 0 !important;
  }

  th, td {
    border: none;
  }

  .invoiceSecondTitle,
  .invoiceData {
    width: 100%;
    
  }
  .invoiceSecondTitle p {
    font-family: 'cairo';
    font-size: 13px;
    font-weight: 900;
    text-align: center;
    margin: 7px;
    padding: 0;
    
  }

  .upTable {
    width: 100%;
    border: none;
  }

  .invoiceTotal1,
  .invoiceTotal2 {
    display: inline-block;
    width: 100%;
  }
}

.body .dataDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
  font-size: calc(8px + 1vw);

  max-width: 440px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: -1px 1px 4px rgba(51, 51, 51, .2);
    background-color: #fff;

}

.invoiveLogo {
  width: 80%;
  margin: auto;
 
}

.invoiceData div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoiceData {
 
  border-bottom: 1px solid #e0e0e0;
}

.invoiceData p {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  letter-spacing: -0.3px;
  color: #4f4f4f;
}

.invoiceData .invoice-value {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  
  letter-spacing: -0.3px;
  color: #331900;
}

.invoiceTitle {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 58px;
  letter-spacing: -0.3px;
  color: #331900;
  text-align: center;
  margin-bottom: 0;
}

.invoiceTable th {
  text-align: start !important;
  font-weight: 900;
  font-size: 18px;
}

.invoiceTableDiv {
  box-shadow: none !important;
  padding-bottom: 5px;
  border: none;
}

.invoiceTotal1 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 20px !important;
  line-height: 36px;
  letter-spacing: -0.3px;
  color: #331900 !important;
}

.invoiceTotal2 {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 20px !important;
  letter-spacing: -0.3px;
  color: #331900 !important;
}

.caution {
  text-align: start;
  padding-top: 5px;
}

.invoiceDiv {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.invoiceTableDiv .table {
  min-width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border: none;
}

@media screen and (max-width: 500px) {
  .invoiceTitle {
    font-size: 24px;
    line-height: 0;
    margin-bottom: 5px;
  }

  .invoiceData p {
    font-size: 18px;
  }

  .invoiceData .invoice-value {
    font-size: 17px;
  }

  .invoiceTotal1 {
    font-size: 20px !important;
    line-height: 30px;
    margin-bottom: 0;
  }

  .invoiceTotal2 {
    font-size: 23px !important;
  }

  .invoiceTable {
    min-width: 0 !important;
  }

  .invoiceDiv {
    padding: 13px !important;
  }
}
