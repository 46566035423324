.tables{
    padding: 20px;
    border: 2px solid grey;
    border-radius: 20px;
    margin-top: 2em;
    min-height: 23vh;
}
.avail{

    background-size: 50px;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 18px;
    text-align: center;
}
.availability p{
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'cairo';
}
.waitBtn{
    bottom: 0 !important;
}
.availability img{


}
.availability .col{
    text-align: start;
}
.availability{
    margin-top: 3em;
}
.tableReserve{
    padding: 35px;
    box-shadow: 12px 0 15px -4px hsl(0deg 0% 92% / 64%), -12px 0 8px -4px hsl(0deg 0% 92% / 64%);
    font-weight: 600;
    color: #633235;
    text-align: start;
    position: relative;
    height: 89vh;
}
.tableReserve label{
    font-size: 1.3rem;
    font-family: "Cairo";
display: block ;
}
.tableReserve select{
    width: 100%;
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 10px;
    margin-bottom: 3em;
    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}

.tableReserve input{
    width: 100%;
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 10px;
    margin-bottom: 3em;
    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}
.closeTable{
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    background-color: #e47100;
    border: 0;
    color: #fff;
    height: 60px;
    text-align: center;
    font-weight: 700;
    line-height: 58px;
    margin-right: -14px;
    font-family: "Cairo";
    border: 2px solid #e47100;
}

.tableReserve button:hover{
    color: #633235;
    background: #f58617;
    border: 2px solid #f58617;

}
.openTable{

    display: inline-block;
    width: 100%;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    line-height: 58px;
    background-color: #fff;
    color: #e47100;
    border: 2px solid #e47100;
    font-family: "Cairo";
}