.mainDiv{
    display: inline-block;
    height:100vh;
    width: 50%;
  }
  body{/*
    margin: 0 !important;
    padding-right: 2em;
  
    */
  }
  
  .waitList{
  
    background: rgba(99, 50, 53, 0.05);
   height:100vh;
   overflow-y: scroll;
   /* max-width: 500px;*/
   width: 33vw;
   
    
  }

  /* .row {
    overflow-x: hidden;
  }

  .row1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-fluid {
    padding: 0;
    overflow-x: hidden;
   

  }
  .col-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
   */
   
  .logo div{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 5.5vw;
  
    width: 15vw;
  }
  .enVector{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    right: -200px;
    left: auto !important;
  }
  .logo {
    background-color: #420F12;
    border-radius: 0px 0px 8px 8px;
  
    padding: 0 1em;
  margin:0 1em;
    display:  inline-block;
  }
  .mainDiv2{
    text-align: start;
  }
  
  .titleW{
   
  font-weight: 800;
  font-size: 2.4vw !important;
 
  text-align: center;
  color: #633235;
  
  }
  .titles{
    font-weight: 800;
  font-size: 18px;
  margin-top: 0;

  
  color: #633235;
 
  }
  .titles p{
  margin-bottom: 0;
    text-align: center;

    
  }
  .orderNumber{
  
  margin-top: 1.5em;
  text-align: center;
  }
  .orderNumber p{
    font-family: 'Cairo';
    font-size: 1.8vw;
  }
  .flexAlign{
    display: flex;
    justify-content: space-between;
  
  }
  .time p{
    color: #FF9900;
    margin-bottom: 0;
  font-size: 30px;
  
  }
  .time{
    text-align: center;
    background: rgba(99, 50, 53, 0.06);
    border-radius: 50px;
   max-width: 170px;
    margin: auto;
  }
  #speak{
   display: none;
  }
  .orderNumber .row{
    position: relative;
    /*left: 1em;*/
  }
  .number p{
    text-align: center;
    color: #FF9900; 
   margin-bottom: 0;
  font-size: 1.8vw;
  }
  .number{
    /*max-width: 115px;*/
    width: 10vw;
    margin: auto;
    background: rgba(255, 153, 0, 0.14);
    border-radius: 50px;
  }
  
  #vector{
    position: absolute;
    bottom: 90vh;
    left: -200px;
    height: 17vw;
  }
  .mainTitle{
    font-family: 'Cairo';
  font-style: normal;
  font-weight: 800;
  font-size: 4vw;
  margin-bottom:0.5rem;
  
  color: #633235;
  
  }
  .brnchName{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 800;
    font-size: 1.8vw;
    
    
    color: #633235;
  }
  .order{
    position: relative;
  top: 26vh;
  opacity: 0;
  display: none;
  transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;


  }

  .order.visible {
    opacity: 1;
    display: block;
    top: 0; /* Move it up into place */
  }

 
 

  .order div{
    background: rgba(255, 153, 0, 0.14);
  border-radius: 50px;
  padding: 4vh 0;
  max-width: 34vw;
  margin: auto;
opacity: 0;
display: none;
  transition-duration: 2s;
  transition-timing-function: ease;
  }
  .test{

    transform: translateY(-16.5vh);
  }
  .test div{
    opacity: 1;
    display: block;
  }
  
  .order div p{
  
    font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  margin-bottom: 0;
  color: #633235;
  
  }
  .p1{
  
    line-height: 2.5vw;
  }
  .brnchImg{
    height: 48vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
   
    width: 50vw;
    left: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  }
  .brnchImgEn{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
right: 0;
background-position: right !important;
    left: auto !important;
  }
  @media (min-height: 900px) {
    .brnchImg {
    height: 55vh;
    width: 55vw;
    }
  }

  @media (min-width: 1700px) {
    .number p {
    font-size: 2.1vw;
    }
    .order div p{
      font-size: 1.9vw;

    }
  }

  .hidden {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .visible {
    display: block;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }