.areas table{
width: 100%;
margin-bottom: 0;
}

table th{
   
        color: #633235;
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -.3px;
        line-height: 24px;
        padding: 2em 1em;
     
} 

.areaTable thead{
    position: sticky;
    top: 67px;
    background-color: white;

}
.title{
text-align: start;
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.3px;
    line-height: 24px; 
    margin-top: 2em; 
} 

.areasTitle{
    display: flex;
    justify-content: space-between;
}
.sortBy p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.3px;
    display: inline-block;
    color: #4F4F4F;
margin-bottom: 0;
}
.sortBy{
    display: flex;
    align-items: center;
    justify-content: end;
} 
.areaSrch{
    text-align: end;
    margin: 0 1em;
}

.areaSrch
.areaSrch{
    position: absolute;
    top: 5px;
    color: #d0d0d0;
    left: -10px;
}
.areaSrch
.areaSrchEn{
    right: -10px;
    left: auto !important;
   
}
.noResult{
    font-family: 'Cairo';
    font-size: 18px;
}

.areaSrch input{
    border: 2px solid #d0d0d0;
    outline: 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    font-family: 'Cairo';
    background-color: #fff;
}
.sortBy p{
}
.sortBy select{

    width: 170px;
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 5px;
 
    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}
.sortBy .css-b62m3t-container {


    width: 170px;
    cursor: pointer;
}
.sortBy .css-tlfecz-indicatorContainer{

    padding: 0 !important;
    cursor: pointer;
}

table td{


    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    color: #989494;
    font-weight: 500;
line-height: 90px;
}
.rowData{
    background-color: rgb(251, 251, 251);
   
}
.areas{
    border-radius: 15px;
    box-shadow: 4px 6px 15px 15px hsl(0deg 0% 95% / 62%);
   
}


@media screen and (max-width: 836px) {
    .reset svg{
        display: none;
    }
    .reset{
        width: 100%;
    }
   
}
@media screen and (max-width: 762px) {
    
    .areas {
        overflow: scroll;
    }
    .areaTable thead{
        position: static;
       
    }
    table.table thead th{
        padding:15px !important;
        line-height: 1em;
    }
}
@media screen and (max-width: 550px) {
    .title{
        margin-top: 1em;
    }
    .filterTitile p{
    font-size: 20px !important;
}
.filtering button{
    font-size: 14px;
}
.filtering input{
    font-size: 14px;

}
.filtering select{
    font-size: 14px;

}
.table td{
    font-size: 14px;

}
.filter{
    padding: 0.5em 1em !important;
}
.areaContainer{
    padding: 1em !important;
}
}