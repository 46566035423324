
.branchScroll p{
  font-family: Cairo;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 0;
  padding-bottom: 1em;
  border-bottom: 1.5px solid transparent;

}

.branchScroll p:hover{
  color: #E47100;
  border-bottom: 1.5px solid #E47100;

}
.branchScroll .activeBranch{
  color: #E47100;
  border-bottom: 1.5px solid #E47100;

}
.branchScroll{
  margin-bottom: 2em;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1.5px solid rgba(128, 128, 128, 0.158);
  overflow: scroll;
}
.questions{
text-align: start;
padding: 2em 3em;
}
.question-title{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #633235;

  }
  .question {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
margin-bottom: 0.5em;
    letter-spacing: -0.3px;
    
    color: #DB095B;
    
  }
  .question-answer{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #E47100; 
  }
  .screenTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  
    letter-spacing: -0.3px;
    margin-bottom: 1em;
    color: #E47100;
  }



.accordion-button{
  background: white!important;
border: none !important;
padding: 0 !important;
align-items: center;
justify-content: space-between;
}
.accordion-button:not(.collapsed){
  box-shadow: none !important;
}
.accordion-button:focus {

  border-color: transparent !important;
  outline: none !important;
  box-shadow: 0 0 0 0.1rem transparent  !important;
}

.accordion-header{
  background: white!important;
  border: 1px solid rgba(0,0,0,.125) !important;
  padding:  1.25rem;


border-top: none !important;
border-radius:  0


}

.accordion-body{
  border: 1px solid rgba(0,0,0,.125) !important;
border-style: none solid solid solid !important;
}
.accordAr .accordion-collapse{
  border-right: 4px solid #E47100;
}
.accordAr .accordion-body{
  border-style: none none solid none !important;
}
.accordEn .accordion-collapse{
  border-left: 4px solid #E47100;
}
.accordEn .accordion-body{
  border-style: none none solid none !important;
}
.accordion-button::after{
  display: none;
}
.accordion-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-header svg{
 color: white;
 transform: rotate(180deg) ;
 transform-origin: 10px 9.5px;
 transition: 0.5s;

}
.collapsed  div{
  border: 1px solid rgba(128, 128, 128, 0.372) !important;
background-color:  white !important;

  border-radius: 50%;
  padding: 0.1em;
 
}
.collapsed svg{
  color: #E47100 !important;
  transform: rotate(0deg) !important;
  transition: 0.5s;
}
.accordion-header div{
  border: 1px solid #E47100;
background-color:  #E47100;

  border-radius: 50%;
  padding: 0.1em;

}
.accordion{
  box-shadow: 0 1rem 3rem rgba(0,0,0,.12)!important;

  border-radius:10px;
}
.accordion-item{
  border: none !important;
}
.accordion-header{
  border-style: none none solid none !important;
}
.questions .pagination,.externalUsers .pagination{
  display: none;
}

.questions .dataTables_info ,.externalUsers  .dataTables_info{
  display: none;
}
.externalUsers .css-nen11g-MuiStack-root{
  margin-top: 1em;

}
.questions label{
display: none !important;
}
.questions select{
  display: none !important;
  }
  /* .questions input{
    display: none !important;
    } */
    .filtering select{
      display: block !important;
    } .filtering input{
      display: block !important;
    font-family: 'Cairo';
  
    }
    .css-319lph-ValueContainer{
      cursor: text;
    }
    .filtering label{
      display: block !important;
    }
.alignVertEnd{
  display: flex;
  align-items: end;
}
    .questions .css-nen11g-MuiStack-root{
margin-top: 0em;
    }
    .noQuestions{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0 1rem 3rem rgba(0,0,0,.12)!important;
      border-radius: 10px;
      padding: 3em 0;
      position: relative;
      top: 15vh;
    }
    .noQuestions p{
      font-size: 30px;
    
    }
    .noQuestions svg{
font-size: 70px;
margin-bottom: 0.1em;
    }
    .noQuestions path{    stroke: #E47100}

   .empty .table-striped>tbody>tr:nth-of-type(odd)>* {
      text-align: center ;
     
  }

  .emptyAr .table-striped>tbody>tr:nth-of-type(odd)>*::after{
    content: ' لا يوجد نتائج ';
    text-indent: 0;  
 display: block;
padding: 1em;
  }
  .emptyAr .table-striped>tbody>tr:nth-of-type(odd)>*{
    text-indent: -9999px;  
   line-height: 0;
  }
 
  .reset path{    stroke: white}
  .reset{
    background-color: #633235;
    font-family: 'Cairo';
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.45em 1em;
  }
  .reset svg{
    margin: 0 0.5em;
  }
  .reset:hover{
    background-color: #783d41;

  }
.actionBtn{
  display: flex;
  justify-content: space-between;
}
.usrType{
  top: 50% !important;
}

.usrType .col{
display: flex;
align-items: center;

}
.usrType label{
 margin: 0 0.5em;
  }
  .confirm{
    font-family: 'Cairo';
    color: white;
    background-color: #E47100;
    border: none;
    padding: 0.5em 1em;
    border-radius: 8px;
    margin-top: 36px;
  }
  .confirm:hover{
    background-color: #ed841b;

  }
  .usrLink{
    color: #E47100 !important;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 400  !important;
    margin: 0 !important;
    text-align: center !important;
  }
  .usrLink:hover{
    color: #f59230 !important;

  }


  @media screen and (max-width: 550px) {
    .screenTitle{
      font-size: 18px;
    }
    .question-title{
      font-size: 14px;
text-align: start;
    }
    .question-answer{
      font-size: 14px;

    }
    .questions{
      padding: 2em;
    }
  }