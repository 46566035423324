.date-picker {
  margin-top: 2vh;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.date-input {
  margin-right: 10px;
  align-items: stretch;
  justify-content: space-between;
  width: 477px;
}

.date-input label {
  margin: 5px;
}

.date-input input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-picker button {
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #e47001;
  color: #fff;
  cursor: pointer;
  width: 13vw;
}

.datepicker {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  font-size: 1rem;
  width: 15vw;
}

.datepicker:hover,
.datepicker:focus {
  border-color: #007bff;
}

.datepicker::-webkit-calendar-picker-dropdown {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.datepicker::-webkit-calendar-picker-indicator {
  color: #007bff;
  font-size: 0.8rem;
}
.data-container {
  margin-top: 9vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 10px;
}

.data-item {
  font-family: "Cairo";
  background-color: #e47001;
  padding: 10px;
  border-radius: 9px;
  text-align: center;
}

.data-title {
  font-family: "Cairo";
  text-align: center;

  font-weight: bold;
  margin-bottom: 5px;
  font-size: 24px;
  color: #fff8e7;
}

.data-value {
  font-family: "Cairo";
  margin-top: 5vh;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 24px;
  color: #fff8e7;
}
