.profileLoader{
    height: 56vh !important;
}
.profileBody{
    margin-top: 2.5em ;
    padding-bottom: 120px;
}
.upTable-title{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
text-align: start;
font-size: 22px;
line-height: 45px;
letter-spacing: -0.3px;
color: #331900;
}
select, .css-wsp0cs-MultiValueGeneric, .css-1nmdiq5-menu{
  font-family:  Cairo !important;
  text-align: start !important;
}
.clientCode .css-1p3m7a8-multiValue{

min-width: unset !important;

}
.loginContainer{
padding-bottom: 10em;
}
.clientCode .css-3w2yfm-ValueContainer{
  flex-wrap: nowrap !important;
  padding: 5px 10px;
  overflow: auto !important;
}
.clientCode .css-1p3m7a8-multiValue{
  overflow: visible !important;
  width: auto !important;
}
.css-1jqq78o-placeholder{
  text-align: start;
  padding: 0 10px;
  font-family:  Cairo !important;

}

.css-t3ipsp-control{
  border: 2px solid #E47100 !important;
  box-shadow: none !important;

}
.css-166bipr-Input input:focus{
  border: none !important;
}
.external-container .css-b62m3t-container{
padding: 0 !important;
}
.usrType .categList{
  margin-top: 18px !important; margin-bottom: 8px;
  width: 100% !important;
}
.alignChoice {
  display: flex;
  align-content: center;
  justify-content: start;
}
.alignChoice input{
  position: relative;
  top: 5px;
}
.limitP{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 45px;
  letter-spacing: -0.3px;
  color: #331900;
}
.userDataExt{

    text-align: center;
    padding: 3em 0;
    background: #F4F4F4;
    border-radius: 12px;

}
.userDataExt label{

    margin-top: 1em;
        display: block;
    
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    
    margin-bottom: 0.5em;
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    
       }
   
.verify{
  display: flex;
  align-items: end;
 
}
.verify button{
  width: 150px;
}
       .userDataExt  .auth{
         width: 93.5%;
         margin: 1em 0;
         margin-bottom: 0;
       }

   .inputDiv{

    width: 55%;
    margin: 0 !important;
    text-align: start;
     }
       .userDataExt input{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    
    letter-spacing: -0.3px;
    
    color: #633235 ;
        border-radius: 8px;
        letter-spacing: -0.3px;
        border: none;
       
     width: 100%;
        padding: 0.5em;
    }


.userDataExt .upImageBorder{
    padding: unset;
    border-color: transparent;
}
.userDataExt input{
    background-color: white;
}

.userDataExt .profileImage{
   width: 13vw;
  height: 13vw;
  min-height: 90px;
  min-width: 90px;
}

.userDataExt span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;
margin: 0.2em 0;
   }
   .userDataExt select{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: none;
    color: #ACACAC;
 width: 100%;
    padding: 0.5em;

   }
   .userDataExt  .inputDiv{

    width: 85%;
    
     }
.selectType{
  border: 1px solid #cdcdcd !important;
padding:6px 0.5em !important;
}
.textNote{
  height: 74px;
  padding: 5px;
}
     .usrTabs{
        border-bottom: 1px solid #cdcdcd;
        justify-content: space-between;
        margin-bottom: 2em;
        display: flex;
        overflow: scroll;
    }
    .usrTabs p{
        align-items: center;
        border-bottom: 2px solid transparent;
        color: #4f4f4f;
        cursor: pointer;
        display: inline;
        display: flex;
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -.3px;
        line-height: 27px;
        margin-bottom: 0;
        padding: 0.3em 1em;
    }
    .inputDiv .uploadInp{
        width: 100%;
    }
    .usrTabs p:hover{
        border-color: #e47100!important;
        border-width: 2px;
        color: #e47100!important;
    }
    .usrTabs .activeTab{
        border-color: #e47100!important;
        border-width: 2px;
        color: #e47100!important;
    }
    
    .inputDiv .uploadInp{
        width: 100%;
    }

 .userDataExt  .inputDiv{

    width: 55%;
    margin: auto;
    text-align: start;
     }
     .changePass{
        background-color: transparent;
        border: none !important;
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    margin-top: 0.5em;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    
    color: #4F4F4F;
    
    
    }
    .dataEdit{
        background: #E47100;
    border-radius: 8px;
    border: none;
    
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 37px;
    
    letter-spacing: -0.3px;
    
    color: #FFFFFF;
    width: 20%;
    }
    .dataEdit:hover{
      background-color: #f48312;
    }
    .clientCode{
        text-align: start;
    }
    .clientCode label{
        margin: 0.5em 0;
        margin-top: 1em;
    }
    .clientCode input{
        width: 100% !important;
   
        padding: 1.2em 0.8em;
        border-radius: 8px;
        border: 1px solid #d4d2d2;
        display: block; 

    }
    .otp div{
      width: 250px;
            }
          
             .otp input{
             padding: 1.2em 0 !important;
             width: 80% !important;
                     }
    .usrType{
padding: 48px !important;
    }
 .userDataExt  .inputDiv{

    width: 85%;
    
     }
     .usrType .alrt{
font-size: 18px;
margin-bottom: 8px;
     }
     .clientCode label{
      margin-top: 18px; 
      margin-bottom: 8px;
     }
.status{
  font-family: 'Cairo';
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 0 !important; 
  text-align: end !important;
  width: 100%; 
}
.formBtns{

    margin-top: 0em;
   
}
.formBtns span{


display: inline !important;
margin: 0 1em !important;
}
.cancelEdit{
width: 20%;

    border: 1px solid #E47100;
    border-radius: 8px;
background-color: transparent;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 37px;

letter-spacing: -0.3px;

color: #E47100;

}
.cancelEdit:hover{
background-color: #E47100;
color: white;
}
.uploadInp{
    position: relative;
    width: 80%;
    margin: auto;
  }
  .uploadInp input{
    width: 100% !important;
  }
  label svg{
      cursor: pointer;
     
  }
  label svg:hover{
color: #e47100;
   
}
  .noPadding{
    padding: 0 !important;
  }
  .uploadInp span{
    width: 100%;
  }
  .uploadInp svg{
    position: absolute;
    left: 1em;
    bottom: 0.8em;
    cursor: pointer;
    pointer-events: none;
  }
  .uploadInpEn svg{
    left: auto ;
    right: 1em ;
  
  }
  .decline{
    color: rgb(220, 43, 43) !important;
    background-color: rgba(165, 42, 42, 0.162);
  }
  ::-webkit-file-upload-button {
    display: none;
  }
  
  input[type=file] {
  cursor: pointer;
  
  }
.inputDiv .uploadInp{
    width: 100%;
}
.navigateSteps svg{
    margin: 0 0.5em;
  }
  .navigateSteps a {
    font-family: 'cairo';
  font-size: 16px;
    text-decoration: none !important;
  }
  .noBranches{
    font-family: 'cairo';
    font-size: 16px;
    font-weight: 600;
    color: rgb(79, 34, 34);;
  }
   .navigateSteps a:hover {
  color: #E47100 !important;
  
  }
 

.mapAlign{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigateSteps svg{
    margin: 0 0.5em;
  }
  .loginForm select{
    
    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #ACACAC;
border: none;
padding: 0.5em 10px;
width: 80%;
}
  .loginTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;
letter-spacing: -0.3px;
text-align: center;
color: #420F12;

}
  .navigateSteps{
    text-align: start;
    margin-top: 2em;
  }
  .regStep input{
      width: 80%;
  }
  .navigateSteps a {
    font-family: 'cairo';
  font-size: 16px;
  color: black ;
    text-decoration: none !important;
  }
   .navigateSteps a:hover {
  color: #E47100 !important;
  
  }
.regStep label{
    width: 80%;
margin: auto;
margin-bottom: 1em;
margin-top: 1em;

  }
  .regStep{
      width: 100%;
      max-width: none;
  }
  .regStep span{
    width: 80%;
    margin: auto;
  }

.inputDiv a{
cursor:pointer;
}
.inputDiv a svg{
    margin-top: 0.5em;
}
.inputDiv a path{
stroke: #b30c00;
} 
  .map{
    width: 100%;
    height: 450px!important; 
  } 
  .authTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px !important;

    letter-spacing: -0.3px;
    text-align: start;
    color: #420F12;
    width: 80%;
    margin: auto;
margin-top: 0;
    margin-bottom: 0;
  }
  .auth{
    display:flex;
    justify-content:space-between;
    width:80%;
    margin:auto;
    margin-top: 2em;
    align-items: center;
   }
   .addAuth{
     max-width: 200px;
     padding: 0;
   }

.navigateReg button{
    width: 30%;
  }
  .navigateReg{
    margin-top: 2em;
  }
  .navigateReg span{
    display: inline;
  }
  
.contReg{
display: flex;
align-items: center;
justify-content: space-between;
}
.contReg button{
    border:1.5px solid #e47100;
    color: #e47100;
    background-color: white;
    font-family: 'Cairo'; 
    padding: 0.5em 1em;
    border-radius: 8px; 
    margin-bottom: 1em;
}
.contReg button:hover{
    background-color: #e47100;
    color: white; 
}

  .navigateReg .prev{
    border:2px solid #e47100;
    color: #e47100;
    background-color: white;
  }
  .navigateReg .prev:hover{
    background-color: #e47100;
    color: white;
  
  }
  .foodModal, .modalOverlay {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}
.foodModal img{
    z-index: 12;
    max-width: 90vw;
}
.flexEnd{
  display: flex;
  align-items: flex-end;
}
.externalList .dropdown-check-list ul.items li {

justify-content: start;

}
.externalList .dropdown-check-list ul.items span {

display: inline-block;
color: #331900 !important;
}
.externalList .dropdown-check-list input[type="checkbox"]{
width: 20px !important;
}

.disabledForm .uploadInp{
  display: none;
}

.disabledForm select, .disabledForm input{
  pointer-events: none;
  -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
background-image: none;
background-color: transparent;
}
.chosenSelect{
  color: #633235 !important;

}
.disabledForm span{
  display: none;
}
.disabledForm label{
  font-weight: 700;
}
.profileReg1Ar .disabledForm input[type="date"]{
  text-align: end;
padding: 0;
}
.profileReg1En .disabledForm input[type="date"]::-webkit-calendar-picker-indicator{
    left: 0;
    position: absolute;
}
.profileReg1En .disabledForm input[type="date"]{
  position: relative;
  padding-left: 1.2em;
}

.regStep3 input[type=file], .regStep3Ar input[type=file]{
  cursor: pointer;
  color: transparent !important;
  }
  .regStep3 input[type="file"]::before {
  content: "File uploaded";
  color: gray;
  position: absolute;
}

.regStep3Ar input[type="file"]::before {
  content: "الملف مرفوع";
  color: gray !important;
  position: absolute;
}
.regStep3 .clientCode input[type="file"]::before {
  content:none !important;
  color: gray !important;
  position: absolute;
}
 .clientCode input[type="file"] {
  color: gray !important;

}
.regStep3Ar .clientCode input[type="file"]::before {
  content:none !important;
  color: gray;
  position: absolute;
}


.regStep3Ar .fileUploaded input{
  color: #633235 !important;

}
.regStep3Ar .fileUploaded input[type="file"]::before{
  content:none
}
.selectedBranches{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #633235;
 }

@media screen and (max-width: 575px) {
  .auth button{
    white-space: nowrap;
width: auto;
padding:0 0.5em;

  }
  .navigateSteps a{
    font-size: 14px;

  }
  .navigateSteps svg{
    margin: 0;
  }
  .loginTitle p{
    font-size: 18px;
  }
  .profileBody{
    margin-top: 1em;
  }
  .upTable-title{
    font-size: 18px;
    margin-bottom: 0.5em;
  }
  .userDataExt{
    padding: 1em !important;
  }
  .usrTabs{
overflow-y: scroll;
margin-bottom: 1em;

  }
  .usrTabs p{
    font-size: 14px;
    white-space: nowrap;
    line-height: 50px !important;
  }
  .userDataExt .inputDiv{
    width: 100%;
  }
  .removeCol{
display: none;
  }
  .formBtns button{
min-width: 100px;
  }
 
  .formBtns span{
    margin: 0.5em !important;
  }
  .map{
    height: 300px !important;
  }
  .authTitle{
font-size: 16px !important;

  }
  .userDataExt .auth{
    width: 100%;
  }
  .userDataExt span{
    font-size: 14px ;  
  }
 .authPadding .noPadding{
  padding-right: calc(var(--bs-gutter-x) * .5) !important;
  padding-left: calc(var(--bs-gutter-x) * .5) !important;
 }
 .inputDiv a svg{
   height: 20px;
   width: 20px;
 }
 .uploadInp svg{
   display: none;
 }
}