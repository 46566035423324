@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800&display=swap');
.App {
  text-align: center;
}

body{
  margin: 0;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea::placeholder{
  font-family:"Cairo";
}
textarea{
  font-family:"Cairo" !important;
}