.chicken{
 
    background-repeat: no-repeat;
background-size: cover;
background-position: center;
position: relative;
left: -18px;
z-index: -1;
height: 87vh;
width: -webkit-fill-available;
    
--mask: linear-gradient(to left,#0000 30px,#000 0), radial-gradient(150px,#000 98%,#0000) right/300px 177.25px;
aspect-ratio: 1;
-webkit-mask: var(--mask);
      mask: var(--mask);
}



.chickenWave { /*--size: 40px;
--R: calc(var(--size)*1.28);
height: 87vh;
-webkit-mask:
  radial-gradient(var(--R) at 50% calc(1.8*var(--size)),#000 99%,#0000 101%) 
    calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%,
  radial-gradient(var(--R) at 50% calc(-.8*var(--size)),#0000 99%,#000 101%) 
    50% var(--size)/calc(4*var(--size)) 100% repeat-x;
transform: rotate(90deg);
background-color: red;*/
}

.logout{
    margin-bottom: 0;
    font-family: 'Cairo';
font-style: normal;
font-weight: 300;
text-decoration: underline;
cursor: pointer;
color: white;
white-space: nowrap;
}
.logout:hover{
    color: #E47100;

}
.searchCustomer{
    border-radius: 0.25rem!important;
    background: #FFFFFF;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    text-align: start;
    padding: 2em;
}

.searchCustomerInpopup{
    border-radius: 0.25rem!important;
    background: #FFFFFF;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    text-align: start;
    padding: 2em;
    width: 32vw;
    margin-top: 15vh;
}
.title1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 32px;
color:  #331900;
text-align: start;
}
.title2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 32px;
color: #331900;
text-align: start;
}
.alignVert{
  position: relative;
  top: 15%;
}
.searchCustomerInpopup label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
margin-bottom: 0.5em;
    color: #828282;
    display: block;
    text-align: start;
}

.searchCustomer label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
margin-bottom: 0.5em;
    color: #828282;
    display: block;
    text-align: start;
}

.searchCustomerInpopup button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
   
   
    color: #FFFFFF;
    background: #E47100;
border-radius: 7px;
border: none;
padding: 0.5em 1em;
width: 25%;
margin-right: 1em;
}

.searchCustomer button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
   
    color: #FFFFFF;
    background: #E47100;
border-radius: 7px;
border: none;
padding: 0.5em 1em;
width: 25%;
margin-right: 1em;
}
.searchCustomer button:hover{

    background: #f58617;
}
.searchCustomerInpopup input{
    border-radius: 6px;
        border: 1px solid #e5e5e5;
    padding: 0.5em;
   
    }

.searchCustomer input{
border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
width: 100%;
}
.or{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px; 
    color: #633235;
    display: inline-block;
  
}
.orHr{
    border-top: 1px solid #e5e5e5;
    display: inline-block;
    width: 80%;
    position: relative;
    top: 1em;
}
.btn1{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
width: 100%;
    
    /* #E47100 */
    
    color: #E47100;
    border: 2px solid #E47100;
border-radius: 8px;
background-color: transparent;
padding: 0.5em;
}
.btn1:hover{
    background-color: #E47100;
    color: white;

}

.btn2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    
    /* #E47100 */
    border: 2px solid #E47100;
    color: white;

border-radius: 8px;
background-color: #E47100;
padding: 0.5em;
} 
.btn2:hover{
    background-color: #f6891c;
    border: 2px solid #f6891c;
 
}
.cstmrSrch{
    position: absolute;
    right: 5px;
    color: #E47100;
    top: 5px;
}
.chickenSharp{
    width: -webkit-fill-available;
    --mask: linear-gradient(to left,#0000 30px,#000 0), radial-gradient(150px,#000 98%,#0000) right/300px 177.25px;
    aspect-ratio: 1;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    right: -18px;
    position: relative;
transform: scale(-1);
}
.chickenEn{

   

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    transform: scale(-1);
    z-index: -1;
    height: 87vh;
  
}


.validation{
    color: brown ;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 0.8rem;
text-align: start;
display: block;
}
.noResult img{
margin-bottom: 1em;
}
.docsContainer  .dataTables_info, .docsContainer  .pagination, .docsContainer  .bs-select, .docsContainer  .mdb-datatable-filter{
    display: none;


}
.noResult p{

    font-family: 'Cairo';
    color: #9d9d9d;
    font-weight: 600;
    font-size: 1rem;
margin-bottom: 0;
}
.editCstmr select{

    border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
    font-family: "Cairo";
    width: 100%;
    margin-bottom: 1em;
}

.toggle{
    display: none;
}


.onlineStatus{
    max-width: 150px;
    min-width: 150px;
margin-right: auto;
    border-radius: 8px;
    overflow: hidden;
}
.navName{
    display: flex;
    align-items: center;
}


.onlineStatus button{
    border: none;

    font-family: 'Cairo';
    font-size: 14px;
    padding: 0.5em ;
    color: white;
    white-space: nowrap;
  
}
.onlineStatus .online{
   width: 50%;
border-radius:0 8px 8px 0;
background-color: rgb(26, 164, 26);
box-shadow: 0 0 15px rgba(0, 0, 0, 0.414);
clip-path: inset(0px 0px 0px -15px);

}

.onlineStatus .offline{
    width: 50%;
    border-radius: 8px 0 0 8px;
    background-color: rgb(224, 40, 40);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.414);
clip-path: inset(0px -15px 0px 0px);
    }
    .dull{
    box-shadow: none;
        background-color: rgba(53, 56, 53, 0.371) !important;
    }
    .onlineStatusEn .offline{
        border-radius:0 8px 8px 0;
        clip-path: inset(0px 0px 0px -15px);
    }
    .onlineStatusEn .online{
        border-radius: 8px 0 0 8px;
        clip-path: inset(0px -15px 0px 0px);
    }
.onlineStatusEn{
margin-left: auto ;
margin-right: 0;
}
@media screen and (max-width: 991px) {
    .chicken{
display: none;
 
    }
  


.srchPhone{
    height: 100%;
    margin-top: 2em;
}
.col-lg-6 {
    padding:0 2em !important
}
.meals{
    padding:  initial !important;
}
}

@media screen and (max-width: 550px) {
    .onlineStatus{
        max-width: 150px;
        min-width: unset;
    }
    .noResult p{
        font-size: 14px;
    
    }
    .noResult{
        margin-top: 5em;

    }
    .editCstmr label, input, select,button{
        font-size: 14px !important;

    }
 
    .editCstmr p{
        font-size: 16px !important;

    }
    .toggOpen{
        top: 90px !important;
    }
    .ticker span{
font-size: 16px;
white-space: nowrap !important;
    }
    .ticker{
        padding: 0.5em 0;
    }
    .title1{
font-size: 22px;
margin-bottom: 0em;
    }
    .title2{
        font-size: 22px;

    }
    .or{
        font-size: 20px;
 
    }
    .btn2{
        font-size: 14px;
 
    }
    .searchCustomer button{
        font-size: 14px;

    }
    .searchCustomer{
        padding: 1em;
    }
    .btn1{
        font-size: 14px;
 
    }
    .srchPhone{
height: 80vh;
    }
    .alignVert{
        top: 10%;
    }
}

@media screen and (max-width: 375px) {

    .srchField{
        width: 100% !important;
        margin-bottom: 1em;
    }
}
@media screen and (max-width: 1050px) {
   
    .screens{
        display: none !important;
    }
  
    .toggOpen{
        display: block !important;
        position: fixed;
      background-color: white;
      padding-bottom: 1em;
      width: 100%;
      z-index: 20;
    top: 80px;
      }
    .mobileLinks{
        left: 0;
        padding: 3vw ;
        padding-top: 2.3em;
        animation: growDown 500ms ease-in-out forwards;
        transform-origin: top center;
    display: flex !important;
flex-direction: column;
align-items: flex-start !important;
    }
  
    .toggClose{
        display: none !important;
      }
    
.mobileLinks a{
    margin: 0.7em 0 !important;
    margin-top: 0 !important;
    color: #331900 !important;
    font-size: 14px;
}
.mobileLinks svg{
    margin: 0 !important;
    color: #331900 !important;

}


  .chatSvg::before{
    right: 1.5em !important;
  }
.imgAlign{
    margin: 0.5em 0;
    margin-top: 0 !important;

}
.mobileLinks .logout{
    color: #331900 !important;
    font-size: 14px;

}
.mobileLinks select{
    margin: 0.5em 0 !important; 
    margin-top: 0 !important;
    font-size: 14px;


}
.mobileLinks select{
    color: #331900 !important;
}
.usrName{
    color: #331900 !important;
    font-size: 16px !important;


}
.mobileLinks a:hover, .logout:hover{
    color: #E47100 !important
}
.toggle{
    display: block;
    display: flex;
    padding: 0 !important;
    align-items: center;
justify-content: end;
}
.toggle svg{
    color: white;
    cursor: pointer !important;
}
.toggle button{
border: none;
background: transparent;
display: flex;
justify-content: end;
align-items: center;
}
.toggle button:hover svg{
    color: #E47100;
}
input[type="radio"]{
    width: 15px !important;
    height: 15px !important;
}
.showChat{
    margin-bottom: 0 !important;
}
}

.container-cashir {
    margin-top: 2vh;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .card {
    width: 40vw;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    text-align: center;
    height: 40vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    
  }


  

  .card {
    position: relative;
    width: 40vw;
    height: 50vh;
     
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
     background-position: center top;
     background-repeat: no-repeat;
     background-size: contain;
     border-radius: 14.0814px 14.0814px 0px 0px;
     
}

.card .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
   padding: 80px;
    display: flex;
    background-color: #f9f6f6;
    color: black;
    padding: 10px;
    box-sizing: border-box;
    font-size: 18px;
    align-items: center;
     justify-content: center;
}

  /* .card-title:hover {
    background-color: #F5D9BF;
  } */

.customLabel {
    font-family: 'Cairo' !important;
  
}
.buttonDiolog {
    width: 20%;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    line-height: 58px;
    background-color: #fff;
    color: #e47100;
    border: 1px solid #e47100;
    font-family: "Cairo";
}

@media only screen and (max-width: 600px) {
    .tables {
      width: 100%;
      padding: 10px;
    }
  
    /* Adjust the grid for mobile view */
    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 columns on small screens */
      gap: 10px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  
    .col-2 {
      flex: none;
      width: 100%; /* Ensure full width on small screens */
      max-width: 100%;
    }
  
    .avail {
      height: 8vh;
      width: 100%;
      background-size: cover; /* Ensure the background images scale */
    }
  
    /* Adjust buttons for mobile */
    .btn2 {
      width: 80vw;
      margin-top: 4vh;
    }
  
    .buttonDiolog {
      width: 100%;
    }
  
    /* Reduce dialog content height for mobile */
    .MuiDialogContent-root {
      height: auto;
      padding: 10px;
    }
  
    /* Adjust dialog title size */
    .MuiTypography-body1 {
      font-size: 14px;
    }
  }