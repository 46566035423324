
.menu{
    margin-top: 2em;
    text-align: start;
    padding-bottom: 9em;
    position: relative;
    
}
.menuTitle {

    font-size: 1.25rem;
    font-weight:700;
    line-height: 1.5;
 
    font-family: 'Cairo';
}
.carEn  .delPromo{
    left: auto;
    right: 50px;
}
.noAval{
    font-size: 1.25rem;
    font-weight:600;
    line-height: 1.5;
 margin-bottom: 0;
 padding: 2em 0;
    font-family: 'Cairo';
color: brown;

}
.menuSrch{
    display: flex;
    justify-content: space-between;
}
.meals .mealDiv:hover{
    background-color: #F4D9BF;
   
}
.cartItem-quan button:disabled, .cartItem-quan  button[disabled]{

    background-color: transparent !important;
    border: none;
    cursor: not-allowed !important;
}
.delPromo{
    background-color: transparent !important;
    color: brown !important;
    font-size: 25px !important;
    padding: 0 !important;
    position: absolute;
    left: 50px;
    bottom: 4px;
}
.delPromo:hover{
    color:#212529 !important;
}
.delPromoEn{
    left: auto !important;

    right: 50px;
}
.meals .mealDiv{
    padding: 0.5em;
    border-radius: 7px
}
.menuSrch input{
    border: 1px solid #d0d0d0;
    outline: 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;

}
.noteText{
   
    margin-top: 1em;

}
.noProd p{
    color: #495057;
    font-family: 'Cairo';


}
.noProd{

    text-align: center;
    position: relative;
    top: 20%;
}
.srchIcn{
    color: #d0d0d0;
position: absolute;
left: 17px;
top: 5px;
}
.srchIcn1{
    color: #d0d0d0;
    left: 17px;
    position: absolute;
    top: 22px;
    font-size: 26px;
}
.srchIcnEn{
    right: 17px; 
    left: auto !important;
}
.cstmrAddress{
    text-align:start;
    
    margin-top: 1em;
}
.cstmrAddress p{
    font-family: 'Cairo';
}
.categories{

    border-left: 1px solid #BEBEBE;
    padding-left: 0.5em;
    max-height: 100vh;
    

}

.categoriesEn{
    border-left:none;
    padding-left: 0;
    border-right: 1px solid #BEBEBE;
    padding-right: 0.5em;

}
.categories div{
   /* background-color: #633235;*/
    border-radius: 5px;
   /* color: #fff;*/
    font-weight: 600;
    font-family: 'Cairo';
 display: flex;
 align-items: center;
 margin-bottom: 1em;
padding: 0.5em;
cursor: pointer;
}

.non-touch:hover{
    color: #fff ;
    background-color: #633235 ;

}
.activeCategory{
    color: #fff;
    background-color: #633235;


}
.categories img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    float: right;
    
}
.categories p{
    margin-bottom: 0;
}
.meals .info{
cursor: default;
        color: rgb(228, 113, 0) 
}
.tableDisc input{
    border: 1px solid #bebebe;
    border-radius: 5px;
width: 100%;
padding: 0.5em;
}
.meals .mealDiv{
    text-align: center;
}
.meals .mealName{
    cursor: pointer;
    color: #331900;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Cairo';
    margin-bottom: 0;
  
}
.meals .mealDiv div{
cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    width: 90px;
    height: 90px;
    margin: auto;
    margin:  0.5em auto;
    border-radius: 25px;

}
.meals .mealPrice{
    color: #633235;
    font-size: 1rem;
    font-weight: bolder;
    font-family: 'Cairo';
    margin-bottom: 0;
  
}

.meals button { font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
    width: 90%;
    padding: 0.7em 1em;
    margin: auto;
    margin-top: 0.5em;
    }
    .meals button:hover{
        color: #633235;
    background: #f58617;
    }
    .modalOverlay {
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        z-index: 11;
    }
    
    .modalOverlay {
        background: rgba(49,49,49,0.8);
    }
    .mealDetail{
        font-weight: 600;
    font-family: 'Cairo';
    color: #331900;
    font-size: 1.5rem;
    margin-bottom: 0.5em;
    } 
    .meal-name{

        color: #331900;
        font-family: 'Cairo';
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0.5em auto;
    text-align: start;
    
    }
    .meal-detail{
        text-align: start;
        color: #828282;
        font-family: 'Cairo';
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
    .meal-size{
        text-align: start;
        font-size: 1rem;
        color: #633235;
        font-weight: 600;
        line-height: 1.5;
        font-family: 'Cairo';

    }
    .modal-content label{
        font-family: 'Cairo';
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
    }
    .modal-content input{
height: 20px;
width: 20px;

    }
    .flexOption{
        display: flex;
        align-items: center;
        justify-content: start;
    }
    .meal-img{
        width: 100%;
        height: 170px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        border-radius: 25px;
        margin-bottom: 1em;
    }
    .cal{
        font-size: 16px;
      margin-bottom: 0.2em;
        color: #633235;
        font-family: 'Cairo';
        font-weight: 600;
    }
    .calTotal{
        font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: #828282;
    font-family: 'Cairo';
    }
.addMeal{
    color: #fff;
    padding: 7px;
    font-family: 'Cairo';
  font-weight: 400;
    background-color: #e47100;
    border-radius: 5px;
    border: none;
    font-size: 15px;
    width: 100%;
}
.addMeal:hover{
    color: #633235;
}
.total{
    color: #e47100;
    font-weight: 600;
    font-size: 15px;
    font-family: 'Cairo';
}
.flexPrice{
    display: flex;
    justify-content: space-between;
    font-family: "Cairo";
    font-size: 14px;
  align-items: center;
}
.flexPrice p{
    margin-bottom: 0;
}
.quanBtns p{
display: inline-block;
font-weight: 600;
    font-size: 15px;
    font-family: 'Cairo';

color: #331900;
}
.quanBtns button{
background-color: transparent;
border: none;
padding:0 0.5em ;
}
.quanBtns input{
    text-align: center;
    width: 75px;
    border-radius: 5px;
    border: 1px solid #bebebe;
  
height: 30px !important;
}
.cart{

    padding: 20px 10px;
    background-color: #e4e4e4;
    border-radius: 5px;
    height:fit-content;
   /* overflow-y: auto;
    overflow-x: hidden;*/
}
.err{
    font-family: 'Cairo';
    color: brown;
    font-size: 13px;
}
.cartTop{
    display: flex;
    justify-content: space-between;

}
.cartTop p{
    font-family: 'Cairo';
    font-weight: 700;
    font-size: 1.25rem;
}
.cartTop button{
    text-align: center;
    padding: 5px ;
    border: 1px solid #e11857;
    border-radius: 5px;
    cursor: pointer;
    font-family: cairo;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
}
.cartItem-Img{
   
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 100px;
    width: 100px;
}
.cartItem-name{
    color: #331900;
    display: inline-block;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Cairo";
}
.cartItem-delete{
    background-color: transparent;
    border: none;
}
.cartItem{
    margin-top: 1em;
}
.cartItem-detail{

    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Cairo";
}
.cartItem-quan input{
    background-color: #fff;
   text-align: center;
    width: 75px;
    border-radius: 5px;
    border: 1px solid #bebebe;
  height: 25px;
}
li{
    margin-bottom: 0.5em;
}
.cartItem-quan button{
border: none;
background-color: transparent;

}
.cartItem-price{
    margin-top: 0.5em;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Cairo";
    
} 
.cart select{
    width: 100%;
    font-family: "Cairo";
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 5px;
 text-align: right;
    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}





.dropdown-check-list {
    display: inline-block;
  }
  
.srchNote{
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-family: "Cairo"; 
    width: 100%;
    display: flex;
justify-content: space-between;
    align-items: center;
    position:relative;
}
#list1 .srchIcn{

left: 0.2em;
}
.srchNote .srchIcnEn{
    right: 0.2em;
    left: auto !important;
}
 #list1 input[type="text"]{
    width: 100%;
    border: 1px solid #d0d0d0;
    outline: 0;
    padding: 0.2em 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
}

  .dropdown-check-list .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 10px 50px 10px 10px;
    color: #633235;
  
  }
  .dropdown-check-list.visible .srchNote{

border-bottom: none;
border-radius: 10px 10px 0 0;
  }
  
  .dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding: 5px;
    right: 10px;
    top: 30%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  
  .dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
   
  }
  .dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
  }
  
  .dropdown-check-list ul.items {
    padding: 2px;
    display: none;
    margin: 0;
  
    border: 1px solid #E0E0E0;
    border-radius: 0 0 10px 10px;
    border-top: none;
    padding: 0 1em;
  }
  #list1{
    
      width: 100%;
      font-family: "Cairo";
      border-radius: 9px;
      border: none;
      background-color: white;
      padding: 5px;

      font-weight: 300;

      font-family: "Cairo";
  }
  
  .dropdown-check-list ul.items li {
      display: flex;
      font-family: 'Cairo';
      align-items: center;
    justify-content: right;
    list-style: none;
  margin: 0.5em 0;
  }
  .dropdown-check-list ul.items span {
  margin: 0 0.5em;
  
  }
  
  .dropdown-check-list.visible .anchor {
    border-radius: 10px 10px 0 0;
    border-bottom: none;
  }
  
  .dropdown-check-list.visible .items {
    display: block;
    overflow-y: auto;
    max-height: 9em;
  }
  
.cartDivTitle{
    font-size: 1rem; 
    font-weight: 600;
    line-height: 1.2;
    font-family: "Cairo";
}
.cstmrDetails{

    color: #432102;
    font-size: 14px;
    font-family: "Cairo";
}
.cstmrDetails span{
    color: #292929;
    font-size: 16px;
}
textarea{
    width: 100%;
    border: 1px solid #bebebe;
    border-radius: 5px;
}
.paymentType label{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: "Cairo";
}
.paymentType{
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}
.promoCode{
    display: flex;
    justify-content: space-between;
}
.promoCode input{
    border: 1px solid #bebebe;
    border-radius: 5px;
width: 80%;
height: 100%;
} 
.promoCode div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.quantityLoader{
    position: absolute;
    bottom: 6px;
  width: 100%;
  border: 1px solid #bebebe;
  border-style: none solid none solid;
  background-color: white;
}
.promoCode .tick{
    color: white;
    background-color: #2dbb2d;
    border-radius: 100%;

}
.promoCode button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;

    padding: 0.7em 1em;

}
.promoCode button:hover{

    color: #633235;
    background: #f58617;
}
.cartBreakdown p{
    font-size: 14px;
    color: #FFFFFF;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: bold !important;
    margin-bottom: 0;
}
.cartBreakdown {
    padding: 0.5em 0.2em;
    display: flex;
    justify-content: space-between;
    background-color: grey;
align-items: center;
    border-bottom: 1px solid white;
}
.sendOrder{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
width: 100%;
    padding: 0.7em 1em;
    margin: 1em 0;
}
.sendOrder:hover{

    color: #633235;
    background: #f58617;
}
.meals button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
input[type="checkbox"]{

cursor: pointer;
 accent-color: #E47100;
 width: 20px;
 height: 20px;
}
.orderSuccess{
    height: 50vh;
}
.alignCenter{
    position: relative;
    top: 50%;
}
.orderSuccess p{

    font-family: cairo;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 3em;
    margin-bottom: 2em;
}
.orderSuccess .success-btn2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    min-width: 290px;
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
 width: 20%;
    padding: 0.7em 1em;
    margin-top: 0.5em;
    }
    .success-btn2:hover{
        color: #633235;
    background: #f58617;
    }
.cartBtn{
    color: #f58617;  
    cursor: pointer;
    display: none;
}
.orderSuccess span{
    margin: 0 1em;
}
.orderSuccess .success-btn1{
    width: 20%;
    background-color: transparent;
    color: #E47100;
    font-family: 'cairo';
    border: 1px solid #e47100;
        color: #e47100;
        font-size: 15px;
        border-radius: 10px;
        padding: 0 1em;
        height: 45px;
        min-width: 290px;
    }
    .success-btn1:hover{
        background-color: #E47100;
    color: white;
    }
    .mobileDiv{
        display: none;
    }
  
    .cartActn{
        display: none;
        position: fixed;
        left: -5px;
        cursor: pointer;
        color: white;
        background-color:#e47100 ;
        padding:10px 5px ;
        border-radius: 0 8px 8px 0;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;

    }
    .carEn .cartActn{
        left: auto !important;
        right: -5px;
      
      
        border-radius:  8px 0 0 8px ;
    }
    .hiddenOf{
        overflow: hidden;
    }
    .carArrow{
        position: relative;
        top: 5px;
        right: 5px;
    }
    .carEn .carArrow{
        transform: scaleX(-1);
   right: -5px;
        
    }
    .carEn  .carIcn{
        transform: rotate(90deg) scaleY(-1) scaleX(-1) ; 
position: relative;
bottom: 5px;
    }
    .carIcn{
        color: white;
        transform: rotate(90deg) scaleY(-1) ;

    }
    .crtTxt{
        font-family: 'Cairo';
        font-size: 14px;
        transform: rotate(90deg) scaleY(-1) scaleX(-1);
        position: relative;
        top: 6px;
    }
    @media screen and (max-width: 991px) {
        .srchIcn{
            left: 7px;
        }
        .srchIcnEn{
            right: 7px;
        }
        .cartBtn{
            display: inline-block;
 padding: 0;
          border: none;
          background-color: transparent;
    
        }
        .cartActn{
            display: flex;
            
            flex-direction: column-reverse;
        }
        
        .cartClosed{
            left: -400px !important;
        }
        .cart{
            position: absolute ;
         max-width: 90vw;
           width: 330px;
            z-index: 5;
         
            top: 0;
            transition: 1s ease-in-out;

        }

         .cartOpen{
       
            left: -15px;
        }

        .carEn .cartOpen {
            left: auto !important;
            right: -15px;
        }

        .carEn .cartClosed{
            left: auto !important;
            right: -400px ;
        }
    }

    @media screen and (max-width: 667px) {
      
        .srchPos{
            position: relative;
            width: fit-content;
            margin-bottom: 0.5em;
        }
        .menuSrch{
            display: flex;
            flex-direction: column;
        }
        .menuTitle{
            margin-bottom: 0.5em;
        }
    }
    @media screen and (max-width: 575px) {
        .categories{
            display: flex;
            border: none;
            overflow: scroll;
            padding-bottom: 1em;
        }
      
        .categories div{
            margin-bottom: 0;
margin-left: 0.5em;
        }
        .categoriesEn div{
            margin-right: 0.5em;
            margin-left: 0 !important;
        }
        .meals{
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
        }
    .quanBtns{
        text-align: start;
    }
    .total{
        text-align: end !important;
    }
    .mealDetail{
        font-size: 18px;
    }
    .meal-name{
        font-size: 16px;
    }
    .meal-detail, .meal-size, .modal-content label, .cal,.quanBtns p,.total {
        font-size: 14px;


    }
    .calTotal{
        font-size: 13px;

    }
  
    input[type="checkbox"]{
        width: 15px !important;
        height: 15px !important;
        margin-bottom: 0.5em;
    }
    .meal-img{
        height: 90px ;
    }
    .additionalWeb{
        display: none;
    }
    .mobileDiv{
        display: block;
    }
    .menu{
        margin-top: 1em;
    }
    }
    @media screen and (max-width: 550px) {
        .menuTitle{
            font-size: 16px;
        }
        .categories p{
            font-size: 14px;  
        }
        .categories img {
            width: 30px;
            height: 30px;}
            .meals .mealName,.meals .mealPrice{
                font-size: 14px;  

            }
    }
    @media screen and (min-width: 1600px) {

        .cartItem-name{
font-size: 1.2vw;        }
.cartItem-detail,.promoCode input, textarea,.paymentType label,.cartItem-price,.dropdown-check-list.visible .anchor,#list1 input[type="text"],.dropdown-check-list ul.items span {

font-size: 1.1vw;        }

.cartItem-quan input,.promoCode button{

font-size: 1.2vw;        }
.delPromo{
left: 3.5vw;
    font-size: 2vw !important;        }
    .carEn .delPromo{
        right: 3.5vw;

    }
    .cartBreakdown{
        padding: 10px;
    }
.cartItem-quan button {

    font-size: 1.5vw;        }
    .cartTop p{

font-size: 1.5vw;        }
.cartTop button{
padding: 15px;
    font-size: 1.1vw;        }
    .cartItem-delete svg{
        height: 1.1vw;
        width: 1.1vw;
    }
    .cart{
        padding: 25px;


    }
    .cartBreakdown p{
        font-size: 1.1vw;        }
        .sendOrder{

        font-size: 1.2vw;        }
    
    .promoCode input,.noteText{
        padding: 15px;
    }
    .promoCode .tick {
        height: 2vw;
        width: 2vw;  
    }
    .cstmrDetails{

        font-size: 1.1vw;        }
        .cstmrDetails span{

            font-size: 1.2vw;        }

    .cartDivTitle{
        font-size: 1.2vw;        }
        .dropdown-check-list ul.items li{
            margin: 1em 0;
        }

    
}