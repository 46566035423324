
.processNav {
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 6em;
    padding: 0 1em;
    background: linear-gradient(0deg, rgb(20 19 21/78%), rgb(183 61 23/38%)), url("../Images/order.svg");
    position: sticky;
    top: 120px;
    z-index: 10; 
}

.processNav img{

    width: 50px;
}
.dottedLine{

    border-top:1.5px dashed  white;
    display: inline-block;
    width: 15%;
   
} 
.editBtn{
    background-color: transparent !important;
    font-size: 16px !important;
    padding: 0 !important;
    margin: 0 0.5em;
}
.editBtn:hover{
color: #331900 !important;

}
.processNav p{
    color: rgb(228, 113, 0);
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    font-family: 'Cairo';
    
}
.navigateOrder a{
    color: #bebebe;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
    font-family: 'Cairo';
    text-decoration: none;
}

.navigateOrder{
margin-top: 1em;
color: #bebebe;
    text-align: start;
}
.userData{
    color: rgb(228, 113, 0) !important;
}
.navigateOrder a:hover{
    color: black !important;
}
.orderTitle{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
    font-family: 'Cairo';
    color: #331900;
    text-align: start;
    margin-top: 1.5em;
  margin-bottom: 1em;
}
.orderData{

    box-shadow: 2px 1px 9px -4px;
    background-color: #fff;
    border-radius: 7px;
    text-align: start;
    padding: 2em;
    padding-top: 1em !important;
}
.orderData p{

    color: #331900;
    font-weight: 700;
    font-size: 1rem;
    font-family: 'Cairo';
    margin-top: 1em;
    margin-bottom:0.5em;
}
 input[type="radio"]{

    cursor: pointer;
    accent-color: #633235 ;
    width: 20px;
    height: 20px;
}
.orderData .col-12{
 
    display: flex;
    align-items: center;
}
.orderData  label{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: 'Cairo';
    margin-bottom: 0;
}
.datePicker input{
cursor: pointer;
    width: 100%;
    padding: 7px;
    border-radius: 10px;
    border: 1px solid #efaf6f;
    outline: 0;

}
.css-14el2xx-placeholder{
    font-family: 'Cairo';
line-height: 20px;
font-size: 14px;
}
.selectEn #react-select-2-placeholder{
    direction: rtl;
    text-align: left;
}
.datePicker{
  
}
.datePicker .icn{
position: absolute;
color: #E47100;
top: 8px;
left: 370px;

}
.branches .branch div{
    padding: 20px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    border-radius: 15px;
    text-align: center;
    background-color: #e9e9e9;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: 'Cairo';
}
.branches .branch div:hover{


    background-color: rgb(228, 113, 0);
    color: white;
} 
.branches .branch div:hover p{

    color: white !important;

} 
.branchSelectActive  div{

    background-color: rgb(228, 113, 0) !important;
    color: white !important;

}
.branchSelectActive p{
    color: white !important;
  
}
.branchSelectClosed  div{
    cursor: default !important;
    background-color: red !important;
    color: white !important;
}
.branchSelectClosed  div:hover{
    color: white !important;

}
.toMenu{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
   
    color: #FFFFFF;
    background: #E47100;
border-radius: 7px;
border: none;
padding: 0.5em 1em;

margin-right: 1em;
}
.toMenu:hover{

    background: #f58617;
}
.addAddress{


    background: #e9e9e9;
    border-radius: 15px;
    padding: 1em;
}

.orderData .col-6{

    display: flex;
    align-items: center;
}
.orderData .orderSource{

    display: flex;
    align-items: center;
}
.orderNum input{

    margin-left: 0 !important;
    width: 100%;
    border: 1px solid #d0d0d0;
    outline: 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
   
}
.dateFilter button { font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 0 1em;
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
    padding: 0.15em 1em;
    cursor: pointer;
    }
    .dateFilter button:hover{
    
    background: #f58617;
    
}
.dateFilter{
    z-index: 1;
    text-align: end;
    position: relative;
}
.filterEn{
    top: 27.5px;
left: auto !important;
right:200px ;
}
.dateFilter div{
    position: absolute;
top: 27.5px;
left: 200px;

}
.dataTables_info{
    font-family: 'Cairo';
}
.col-sm-12{
    font-family: 'Cairo';
}
.dateFilter input{

    border: 1px solid #bebebe;
    border-radius: 5px;
    font-family: 'Cairo';
    padding: 0.2em 0.5em;
}

.orderNum{
    display: flex;
    align-items: end;
}
input::placeholder{
  font-family: 'Cairo';
}

.orderData .orderSource select{
 width: 100%;

 border-radius: 9px;
 border: none;
 background-color: #f5f2f2;
 padding: 5px;

 font-weight: 300;
 color: #633235;
 font-family: "Cairo";

}
.orderData .orderSource input[type="text"]{
  margin-left: 0 !important;
   width: 100%;
   border: 1px solid #d0d0d0;
   outline: 0;

   padding: 0.375rem 0.75rem;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   }
   .orderData input[type="text"]::placeholder{

font-family: 'Cairo';

   }
 
.addAddress .col-6{
    flex-direction: column;
  align-items: start;
  margin-top: 1em ;
}

.addAddress .col-6 div{
    display: flex;
    align-items: center;
}
.addAddress  p{

    margin: 0.5em 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font-family: 'Cairo';
}
.addAddress  label{
margin-bottom: 0;
    color: black;
    font-weight: 600;
}
.addAddress button { font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

color: #FFFFFF;
background: #E47100;
border-radius: 7px;
border: none;
padding: 0.7em 1em;

}
.addAddress button:hover{

background: #f58617;
}
.orderDetails li{
    text-align: start;
    font-family: 'Cairo';
}
.orderDetails ul{
    padding: 0;
}
.orderDetails span{
    font-weight: 700;
}
.invoice{
    text-align: start;
    font-family: 'Cairo';
    font-size: 22px;
   

}
.editOrder{
    border: none;
    background-color: #E47100;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: end;
    color: #FFFFFF;
    background: #E47100;
    border-radius: 7px;
    border: none;
    padding: 0.5em 1em;
    
    }
    .editOrder:hover{
    
    background: #f58617;
    }
    .orderDet{
        border: 2px solid #ccccce!important;
        border-radius: 9px;
        padding: 1em;
        padding-bottom: 0;
        margin-bottom: 1em;
    }
    .orderData{
        overflow: auto;
        padding-bottom: 0;
        margin-bottom: 2em;
        border-radius: 9px;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.12)!important;
   
        padding: 1em; 
    }
    .invoiceTable{
        overflow: auto;
    
        border-radius: 9px;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.15)!important;
   
        padding: 2em;
    }
    .borderHr{
        border-top:1px solid rgba(0, 0, 0, 0.148) ;
        padding-top: 1em;
    }


.searchInBranchInput {
    width: 18vw;
    padding: 10px;
    margin: 10px auto;
    border-radius: 10px;
    border-color: #f7f6f6;
    border-style: solid;

}