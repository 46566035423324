.login{
    height: 100vh;
    background-repeat: no-repeat;
  background-size: cover;
}
.loginForm{
    background-color: hsl(0 0% 90%/71%);
    transition: background-color 403ms;
/* Note: backdrop-filter has minimal browser support */
max-width: 400px;
border-radius: 20px;

width: 50vw;
padding: 3em;
}
.shareForm{ 
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    padding: 2em;
  
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);

}
.notFountContainer{
    display: flex;
width: 90vw;
max-width: 1000px;

margin: auto;
    align-items: center;
    justify-content: center;
}
.foundUrl{
    height: 40vw;
    max-height: 500px;

    width: 100%;
    padding-bottom: 6vw !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    }
    .fullScreen .notFountContainer{
width: 100vw ;
max-width: unset !important;
margin-top: 0 !important;
padding-bottom: 0 !important;
    }
  
   .fullScreen .foundUrl{
       max-height: unset;
       height: 100vh;
       border-radius: 0;
       padding-bottom: 20vh !important;

       border: none !important;

   }
    .foundUrl p{
    color: white;
    font-size: 5vw !important;
    margin:  0 !important;
    }
.vertAlign{
    height: calc(100vh - 190px);
    display: flex;
    align-items: center;
}
.footerLink{
    font-weight: 600;
    font-family: 'cairo';
    font-size: 14px !important;
    text-align: end;
    padding: 10px 16px;
    background:  #F2F4F7;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
}

.footerLink p{
    margin-bottom: 0;
}
.footerLink a,.footerLink span {
color:   #E47100 !important;
text-decoration: none;
}
.questions{
    padding-bottom: 70px !important;
}
.alignLogin{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 41px;
}
.loginLogo{
    height: 120px;
    background-size: contain;
    background-repeat: no-repeat;
 background-position: center;
}
.loginForm p{
    font-size: 2.5rem;
    color: #212529;
    font-family: 'cairo';
    line-height: 40px;
    white-space: nowrap;
}
.loginForm label{
    text-align: start;
display: block;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;

letter-spacing: -0.3px;
color: #828282;
margin-top: 2em;
margin-bottom: 1em;
}
.loginForm input{
    background: #FFFFFF;
border-radius: 8px;
border: none;
width:90%;
padding: 0.5em 1em;
border: 2px solid transparent
}
.loginForm button{
    background: #E47100;
    box-shadow: 4px 4px 10px rgba(228, 113, 0, 0.1);
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
color: #FFFFFF;
border: none;
margin-top: 1em;
padding: 0.5em;
width: 60%;
cursor: pointer;
}
.loginForm button:hover{
    background: #ec8116;}
    .eye{
        position: absolute;
        right: 17px;
        top: 8px;
        cursor: pointer;
    }

    input:focus{

        border: 2px solid #E47100 !important;
    outline: none !important;
    }
    .modal-content {
        position: absolute ;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: white;
        padding: 14px 1em;
        border-radius: 20px;
      
        z-index: 1500;
    
       display: unset !important;
    width: 500px ;
    height: 571px;
    }
     .invalid{

border: 2px solid brown !important;
    }
    .loginForm span{
       color: brown ;
        font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
text-align: start;
display: block;
    }
    .errMsg{
        text-align: center !important;
        margin-bottom: 0 !important
    }
    .loader span{
        text-align: center !important;
      
    }
    .loader{

        display: flex;
      
        justify-content: center;
        align-items: center;
    }

    @media screen and (min-width: 1700px) {
        .foundUrl p{
            font-size: 60px !important;
        }
        .foundUrl{
            padding-bottom: 5em !important;
        }
    }
    @media screen and (max-width: 700px) {
    

        .foundUrl{
            padding-bottom: 5vw !important;
        }
     .fullScreen   .foundUrl{
            box-shadow: none !important;
            border-radius: 0 !important;
            padding-bottom: 10vh !important;
height:58vh ;
border-radius: 10px;  
        }
       .fullScreen .foundUrl p{
            font-size: 7vw;
        }
        .fullScreen .notFountContainer{
            height: fit-content !important;
            margin: 0 !important;
            margin-bottom: 60px !important;
        }
       .fullScreen {
            height: 100vh;
            display: flex;
            align-items: flex-end;
background-color: #5f2326; 
}
        .loginForm{
    width: 90vw;
     
        }
    .footerLink{
        display: block;
        text-align: center;
    }
    }

        @media screen and (max-width: 550px) {
            .foundUrl{
                padding-bottom: 4vw !important;
            }
            
            .loginForm p{
                font-size: 25px;
            }
            .loginForm button{
                font-size: 14px;
 
            }

            .loginLogo{
                height: 100px;
            }
        }