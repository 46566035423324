.customerImg{
  
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;

    border-radius: 100px;
    width: 7vw;
    height: 7vw;
    max-width: 200px;
    max-height: 200px;
    min-width: 70px;
    min-height: 70px;
 
  
}
input.awafiDate[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute ;
    top: 0;
    right: 0;
    width: 100% ;
    height: 100% ;
    padding: 0;
    color: transparent;
    background: transparent ;
  }
.alignVertMap{
    display: flex;
justify-content: center;
    flex-direction: column;
}
.alignVertMap button{
    display: none;
    width: 100%;
}
.customerBorder{
    border: 3px solid #E47100;
    border-radius: 50%;
    padding: 0.3em;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
}
.customerTop{
    border-bottom: 1px solid #c3c3c3;
    text-align: start;
 
}
.customerName{
    font-size: 28px;
    font-weight: bolder;
    font-family: "Cairo";
    display: inline-block;
    margin-bottom: 0;
}
.customerBtns div{
    position: relative;
 
}
.edit{
    background-color: transparent;
    border: none;
    color: #E47100;
    position: relative;
    bottom: 4px;
}
.edit:hover{
    color: #f98a1a;

}
.customerPhone{

    font-size: 18px;
    font-weight: 500;
    color: #cacaca;
    margin-bottom: 0.5em;
}
.customerBtns div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.customerBtns a, .customerBtns button{
    width: 100% !important;
}
.tags p{
    font-family: "Cairo";
    background-color: #e11857;
    padding: 4px 15px;
    font-size: 14px;
    color: #fff;
    margin: auto ;
    text-align: center;
    display: inline-block;
    margin-top: 1em;
}
.customerBtns button{
    color: #fff;
    font-family: 'Cairo';
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    border-radius: 10px;
    padding: 0.5em 0.5em;
    width: 30%;
}
.customerComplaint{
background-color: transparent;
    color: #633235 !important;
    border: 1px solid #633235;
}
.customerTrack{

border: none;
    background-color: #633235;
}
.customerAdd{
    border: none;
    background-color: #E47100;
}
.customerBtns span{
margin-left: 0.5em;
position: relative;
bottom: 1px;
}
.customerBtnsEn span{
    margin-left: 0;
    margin-right: 0.5em;
}
.customerNav p{
    font-family: 'cairo';
    color: #949494;
    font-weight: 600;
    font-size: 1rem;
    display: inline-block;
    cursor: pointer;
    border-bottom: 2px solid transparent;
padding-bottom: 0.2em;
}
.customerNav{
    text-align: start;
    margin-top: 1em;
}
.customerNav span{

    border-left: 1px solid #949494;
    margin: 0 1em;
}
.customerNav p:hover{

    color: #e47100;
    border-bottom: 2px solid #e47100;

}
.customerNav .activeScreen{

    color: #e47100;
    border-bottom: 2px solid #e47100;

}

.orders-table {
 
    border-radius: 15px;
    box-shadow: 4px 6px 15px 15px hsl(0deg 0% 95% / 62%);
  margin-bottom: 5em;
}
.customerBody{
    padding-bottom: 5em;
}
.customerBody p{
    font-size: 1.25rem;
    font-weight: 700;
    font-family: "Cairo";
    text-align: start;
    margin-top: 1em;
    margin-bottom: 0;
}

 
  .tableEn .dataTables_length label{
 

    left: 3.5em !important;
    
  }
  .tableEn .bs-select select{
   
    left: 7em;

}

.walletTable 
.dataTables_length label{
 

    right: 5.5em !important;
  }

 
  .walletTableEn .dataTables_length label{

    left: 7em !important;
  }
  
.compTableEn .dataTables_length label{

    left: 6em !important;
  }
  .compTable
  .dataTables_length label{

    right: 5em !important;
  }

.orders-table th{

  
    padding: 1.9rem!important;
    margin-bottom: 1rem;
    color: #633235;
    font-family: 'Cairo';
    font-size: 1rem;
}
.orders-table td{

    background-color: rgb(251, 251, 251);
}
.orders-table td{

    color: #989494;
    font-weight: 400;
}
.orders-table button{
    color: #e47100!important;
    background-color: #fef1e4;
    border-radius: 20px;
    padding: 6px 5px;
    font-weight: 600;
 border: none;
padding: 0.5em;
width: 100%;
}
.orders-table button:hover{
color: #f59e47;

}



tbody button{
    color: #e47100!important;
    background-color: #fef1e4;
    border-radius: 20px;
    padding: 6px 5px;
    font-weight: 600;
 border: none;
padding: 0.5em;
width: 100%;
white-space: nowrap;
}
tbody button:hover{
color: #f59e47;

}
.utility-55percent {
    width: 55%;
    text-align: start;
}
.currentCredit{

    background-color: #633235;
  
    padding: 20px 0;
  width: 100%;
    border-radius: 15px;
    margin-top: 1em;
}
.currentCredit p{
    background-color: #633235;
    text-align: center !important;
    font-family: 'Cairo';
   
    color: #fff;
    font-weight: 400 !important;
    font-size: 1rem;
    border-radius: 15px;  
    margin: 0;
    
}
.credit{
    font-size: 40px !important;
    font-weight: 700 !important;
    padding: 0.5em 0;
}
.pointExchange{
 
    border-radius: 15px;
    box-shadow: 4px 6px 15px 15px hsl(0deg 0% 95% / 62%);
 
    padding: 1.5em;
    padding-top: 0.5em;
    margin-bottom: 5em;
}
.pointExchange-title{
    font-family: 'Cairo';
    color: #e47100;
    font-weight: 600;
    font-size: 1.25rem;
}
.pointExchange-exchange p{

    font-family: 'Cairo';
    color: #a7a7a7;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.5em;
  
}
.pointExchange-exchange input{
    padding: 7px;
    border-radius: 10px;
    border: 1px solid #d0d0d0;
    outline: 0;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
}
.pointExchange-exchange{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}
.pointExchange button{
    font-family: 'Cairo';
color: white;
background-color: #e47100;
border-radius: 10px;
padding: 6px 5px;
font-weight: 600;
border: none;
padding: 0.5em;
width: 30%;

}
.pointExchange button:hover{
    background-color: #f68f28;

}
.pointExchange img{
  position: relative;
  top: 30px;
}

.address{

    border-radius: 10px;
    box-shadow: 4px 6px 15px 15px hsl(0deg 0% 95% / 62%);
    padding: 30px;


    text-align: start;
}
.address-name{
    color: #331900;
    font-family: 'cairo';
    font-size: 1rem;
    font-weight: 600;
}
.address-st{
    font-family: 'cairo';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5; 
    color: #9e9e9e;
}
.address button{
    border: none;
    background-color: transparent;
    color: #db095b;
    font-family: 'cairo';
    font-weight: 300;
}
.address button:hover{
    color: #331900;

}
.addressTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addressTop button{

background-color: transparent;
color: #E47100;
font-family: 'cairo';
border: 1px solid #e47100;
    color: #e47100;
    font-size: 18px;
    border-radius: 10px;
    padding: 0 1em;
    height: 45px;
}
.addressTop button:hover{
    background-color: #E47100;
color: white;
}
.addressTop span{
    margin: 0 0.2em;
}
.add-address{
position: relative;
bottom: 1px;
}
.alrt{
    font-family: 'cairo';
    font-size: 1rem;
    font-weight: 600;
}
.modalBtns{
    display: flex;
    margin: 1em 0;
    justify-content: space-around;
}
.modalBtns button{
border: none;
background-color: transparent;
color: #212529;
font-weight: 400;
font-family: 'cairo';
}
.del{
    background-color: #ffc107 !important;
    border-radius: 0.25rem;
    padding: 0.5em 1em;
}
.del:hover{

    background-color: #fcc82c !important; 
}
.addressAdd p{
    text-align: center ;
    margin-bottom: 1em;
}
.addressAdd{

    border-radius: 9px;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;

padding: 1em;
margin-top: 1.5em;
}

.addressAdd label{
    display: block;
    text-align: start;
    font-weight: 600;
    color: #999;
    font-family: 'cairo';
    font-size: 1rem;
    margin-top: 1em;
}
.addressAdd .col-6{
 
    text-align: start;
}
.addressAdd .col-12{
 
    text-align: start;
}
.addressAdd form{
    margin: auto;
  
}
.addressAdd select{
    width: 100%;
 
    border-radius: 9px;
    border: none;
    background-color: #f5f2f2;
    padding: 7px;

    font-weight: 300;
    color: #633235;
    font-family: "Cairo";
}
.addressAdd{
    margin-bottom: 5em;
}
.addressAdd input{


    border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
    font-family: "Cairo";
    width: 100%;

}
.addressAdd textarea{

  padding: 10px;
    height: 80px;
    border: 1px solid #e5e5e5;
}
.addressAdd button{

    font-family: 'Cairo';
    color: white;
    background-color: #e47100;
    border-radius: 10px;
    padding: 6px 5px;
    font-weight: 600;
    border: none;
    padding: 1em 0;
 margin: 1.5em 0;
    
    }
    .uploadBtn{
        text-align: end;
        margin-bottom: 16px;
    }
    .uploadBtn button{
        background-color: #e47100;
        border-radius: 10px;
        font-family: 'Cairo';
        color: white;
        padding: 6px 10px;
border: none;
    }
    .uploadBtn button:hover{
        background-color: #f68f28;
    
    }
    .addressAdd button:hover{
        background-color: #f68f28;
    
    }

    .complaintSend{
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
        padding: 1rem;
        border-radius: 9px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .complaintBody{
        padding: 2rem !important;
        padding-top: 1em!important;
        border-radius: 9px;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    }
    .sendComp{

        font-family: 'Cairo';
        color: white;
        background-color: #e47100;
        border-radius: 10px;
        
        border: none;
       
     margin: 1.5em 0;
        
        }
        .sendComp:hover{
            background-color: #f68f28;
        
        }
    .prevComp{
        
        
        background-color: transparent;
    color: #E47100;
    font-family: 'cairo';
    border: 1px solid #e47100;
        color: #e47100;
        font-size: 18px;
        border-radius: 10px;
     
       margin-bottom: 1.5em;
    }
    .prevComp:hover{
        background-color: #E47100;
    color: white;
    }



    .complaintSend button{
        font-weight: 600;
        line-height: 58px;
        font-family: 'Cairo';
        font-size: 1rem;
        display: block;
        width: 100%;
    }
    .complaintBody p{

        font-size: 1.25rem;
      font-weight: 600;
    font-family: 'Cairo';
    color: #633235;
    text-align: start;
    margin: 1em 0;
}
.complaintBody label{
    color: #633235;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Cairo';
    margin-bottom: 0;
    margin: 0 0.5em;
}
.complaintBody .col{
    display: flex;
    align-items: center;
}
.complaintBody textarea{
    padding: 1em;
    background-color: #f5f2f2;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    font-family: 'Cairo';
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height:10em
}

.editCstmr{


    border-radius: 9px;
    padding: 1em;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    position: relative;
    top: 20%;
    margin-top: 1em;
}
.editCstmr p{
    font-family: 'Cairo';
    color: #331900;  
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0;
}
.editCstmr label{
    font-weight: 600;
    color: #999;
    font-size: 1rem;
    font-family: 'Cairo';
    display: block;
    margin-top: 1em;
}
.editCstmr input{
    border-radius: 6px;
    border: 1px solid #e5e5e5;
padding: 0.5em;
    font-family: "Cairo";
    width: 100%;
    
}
.editCstmr button{
    font-family: 'Cairo';
color: white;
background-color: #e47100;
border-radius: 10px;
padding: 6px 5px;
font-weight: 600;
border: none;
padding: 0.7em;
width: 60%;
max-width: 450px;
margin-top: 1em ;

}
textarea:focus{

    border: 2px solid #E47100 !important;
outline: none !important;
}
.editCstmr button:hover{
    background-color: #f68f28;

}
.editCstmr div{ 
    text-align: start;
width: 60%;
max-width: 450px;
margin: auto;
}

 
.rotate{

    transition: transform 1.2s;
    transform: rotate(360deg);
   margin: 0.5em 0.5em;
 }
  .rotate2{
     transition: transform 1.2s;
     transform: rotate(-360deg);
     margin: 0.5em 0.5em;
  }

  .errModal{


    height:auto;
    
    width:40vw;
    min-width:337px;
    padding-top:2em;
    
    position:fixed;
    top:20%;
    box-shadow:  0 1rem 3rem rgba(0,0,0,.175);

  }
  select{
      cursor: pointer;
  }
  .css-1s2u09g-control {
height: 45px;


  }
 
  .css-qc6sy-singleValue{
    font-family: 'Cairo' !important;

    color: rgb(51, 51, 51);}
    
    .css-26l3qy-menu{
        font-family: 'Cairo' !important;
   
        color: rgb(51, 51, 51);}


       .gender{ display: flex;
        align-items: center;
    }
    .gender label{
        display: inline-block !important;
        margin: 0 0.5em !important;
    }
    .gender input{
        width: unset !important;

    }
    .notAwafi{
        background-color: white;
        box-shadow:  0 1rem 3rem rgba(0,0,0,.175);
        padding: 1em;
        text-align: center;
        margin-top: 2em;
        border-radius: 10px;
    }
    .notAwafi p{
        text-align: center;
        margin-bottom: 2em;
    }
    .notAwafi  button{
        color: #fff;
        font-family: 'Cairo';
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5;
        border-radius: 10px;
        padding: 0.5em 0.5em;
        width: 30%;
        border: none;
        background-color: #e47100;

    }
    .notAwafi button:hover{
        background-color: #f98e23;
    
    }
    .compContaienr{width:90vw;
        max-width: 1000px;
    margin: auto;}
    
    .customerOrders{
        margin-top: 1em;
    }
    .customerOrders .pagination{
        display: none;
      }
      
      .customerOrders .dataTables_info{
        display: none;
      }
      
      .customerOrders label{
      display: none !important;
      }
      .customerOrders select{
        display: none !important;
        }
        .chatBtn{
            border: none;
            background-color: transparent;
        bottom: 2.5px;
        margin: 0 0.3em;
        }
      
        .customerOrders input{
          display: none !important;
          }
          .customerOrders .css-nen11g-MuiStack-root{
            margin-top: 0em;
                }

                @media screen and (max-width: 767px) {
                    .currentCredit p{
                    padding: 0;
                    }
                    #addAddressBtn{
                        display: none;
                    }
                    .alignVertMap button{
                        display: block !important;
                    }
                    .alignVertMap{
                        margin-top: 1em;
                    }
                    .addressAdd p{
                        margin-bottom: 0;
                    }
                }
                @media screen and (max-width: 550px) {

                    .customerName{
                        font-size: 20px;
                        margin-bottom: 0;
                    }
                    .customerPhone{
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    .tags p{
                        padding: 0px 15px;
                        margin-top: 0.5em;
                    }
                   
                    .customerBtns button{
                        white-space: nowrap;
                    }
                    .customerBtns{
                        margin-top: 1.5em;
                    }
                    .complaintBody p, label, textarea{
                        font-size: 14px !important;

                    }
                    .complaintSend button{
                        padding:  0;
                        line-height: 50px;
                    }
                }